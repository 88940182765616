import {
    AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationTooltip, Inject
} from '@syncfusion/ej2-react-charts';
import React, { useState } from 'react';
import './piechart.css'
import IconStoreGreen from "../../../../img/IconStoreGreen.png"
import IconEcommerce from "../../../../img/IconEcommerce.png"
import { useTranslation } from 'react-i18next';

export function PieChart({Id,PiechartData}) {

    const { t, i18n } = useTranslation();
    const tooltipTemplate = pieTemplate;

    const tooltip = {
        enable: true,
        template: tooltipTemplate,
    }
    //TOOTLTIP TEMPLATE
    function pieTemplate(args) {

        return (
            <div id="pieChartTooltip" className="p-2 borderRadius">
                <div className="d-flex tooltipHeader  align-items-center">
                <img className="img-icon20 " src={args.x==='store'?IconStoreGreen:IconEcommerce} />
                <p className=" semibold txtsize-14 ms-2 mb-0"><span 
                className={args.x==='store'?"textGreen":"textPurple"}>{t(`PieChartDashboard.${args.x}`)}</span></p>
                </div>
                <div>
                <p className="textBlack bold text-center txtsize-18 ms-2 mb-0">{args.y.toLocaleString(undefined,{minimumFractionDigits: 2})}%</p>
                </div>
            </div>);
    }

   //GRADIENT COLOR FOR CHART
    function onPointRender(args) {
        if (args.point.x.indexOf('store') > -1) {
            args.fill = ' url(#gradient-chart_2)';
        }
        else if (args.point.x.indexOf('online') > -1) {
            args.fill = ' url(#gradient-chart_1)';
        } else {
            args.fill = '#76C8F4';
        }
    }

    const SAMPLE_CSS = `
        #gradient-chart_1 stop {
            stop-color:#DB91FF;
        }
        #gradient-chart_1 stop[offset="0"] {
            stop-color:#DB91FF;stop-opacity:1
        }
        #gradient-chart_1 stop[offset="1"] {
            stop-color:#B558FD;stop-opacity:1
        }
        #gradient-chart_2 stop {
            stop-color: #5CE8C4;
        }
        #gradient-chart_2 stop[offset="0"] {
            stop-color:#5CE8C4;stop-opacity:1
        }
        #gradient-chart_2 stop[offset="1"] {
            stop-color:#2FCD91;stop-opacity:1
        }  `;


     
    return (
        <>
            <style>
                {SAMPLE_CSS}
            </style>
            <div id="pieChart" className="d-flex" >
               <AccumulationChartComponent id={"pieChart"+Id}
               enableBorderOnMouseMove={false}
                    tooltip={tooltip}
                    pointRender={onPointRender}
                    width='240px' height='240px'
                >
                    <Inject services={[AccumulationTooltip]} />
                    <AccumulationSeriesCollectionDirective >
                        <AccumulationSeriesDirective dataSource={PiechartData} xName='x' yName='y' innerRadius='40%' 
                        explode={true} explodeOffset='8%' explodeIndex={0}  >
                        </AccumulationSeriesDirective>
                    </AccumulationSeriesCollectionDirective>

                </AccumulationChartComponent> 

                <svg style={{ height: '0' }}>
                    <defs>
                        <linearGradient id="gradient-chart_1" x1="0" x2="0" y1="0" y2="1">
                            <stop offset="0" />
                            <stop offset="1" />
                        </linearGradient>
                    </defs>
                </svg>
                <svg style={{ height: '0' }}>
                    <defs>
                        <linearGradient id="gradient-chart_2" x1="0" x2="0" y1="0" y2="1">
                            <stop offset="0" />
                            <stop offset="1" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        </>)
}







import React, { useState } from 'react';
import LogoWhiteSaga from "../../../../img/LogoWhiteSaga.png"
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useTranslation } from 'react-i18next';
export function CardTargetMainChartStartNew({ IconFlag, PrimaryColor, SecondaryColor }) {
    const { t, i18n } = useTranslation();

    return (
        <div id="cardTargetMainChartSuccess" className="borderRadius bgWhite  mx-2 my-3 minHeightCard  flex-column d-flex ">
            <div className="d-flex flex-column p-4 flex1">
                <div className="d-flex  align-items-center mb-3">
                    <img className=" m-2 img-icon20" src={IconFlag} />
                    <h3 className="textBlack txtsize-18 bold mb-0" >{t("CardTargetMainChartStartNew.mytarget")}</h3>
                </div>
                <div className="borderRadius flex1  flex-column d-flex"
                    style={{ background: `linear-gradient(215deg, ${PrimaryColor},${SecondaryColor})` }}>

                    <div className="d-flex flex-column p-4 flex1 justify-content-between" >

                        <div className="d-flex  flex-column" >
                            <div className=" d-flex flex-row  align-items-center " >
                                <img src={LogoWhiteSaga} className="img-icon40 m-2" />
                                <p className=" txtsize-24 semibold  m-0 textWhite" >{t("CardTargetMainChartStartNew.addtarget")}</p>
                            </div>
                            <p className="textWhite txtsize-16 regular m-0" >{t("CardTargetMainChartStartNew.description")}</p>
                        </div>
                        <ButtonComponent style={{ color: PrimaryColor }} cssClass="py-3 my-4 borderRadius bold txtsize-18 text-capitalize">{t("CardTargetMainChartStartNew.add")}</ButtonComponent>
                    </div>


                </div>
            </div>
        </div>
    );
}


import React, { useState } from 'react';
import { ProgressBarComponent, ProgressBarAnnotationsDirective, ProgressBarAnnotationDirective, Inject, ProgressAnnotation } from '@syncfusion/ej2-react-progressbar';



export function ProgressCircle({ id, PrimaryColor, SecondaryColor, min, max, value }) {




    return (
        <>
           
                <ProgressBarComponent
                    id={"lasttargetChart" + id}
                    progressColor={`url(#gradient${id})`}
                    type='Circular'
                    startAngle={210}
                    endAngle={150}
                    width='160px'
                    height='160px'
                    minimum={min}
                    maximum={max}
                    value={value}
                    cornerRadius='Round'
                    trackThickness={16}
                    progressThickness={12}
                    animation={{
                        enable: true,
                        duration: 2000,
                        delay: 0,
                    }}
                >
                    <Inject services={[ProgressAnnotation]} />
                    <ProgressBarAnnotationsDirective>
                        <ProgressBarAnnotationDirective >
                        </ProgressBarAnnotationDirective>
                    </ProgressBarAnnotationsDirective>
                </ProgressBarComponent>

                <svg width="0" height="0">
                    <defs>
                        <linearGradient id={"gradient" + id}>
                            <stop offset="0" stopColor={PrimaryColor}></stop>
                            <stop offset="100%" stopColor={SecondaryColor} ></stop>
                        </linearGradient>
                    </defs>
                </svg>
          
        </>
    );
}


import React, { useState } from 'react';
import "./navbars.css"
//IMAGE AND ICON
import iconBell from "../../../img/IconBell.png"
import iconFaw from "../../../img/IconFaq.png"
import iconUser from "../../../img/IconUser.png"
import IconPinGrad from "../../../img/IconPinGrad.png"
import LogoSaga from "../../../img/LogoSaga.png"
import { useTranslation } from 'react-i18next';

export function NavbarStore() {
    const { t, i18n } = useTranslation();

    return (
        <div id="navbarStore" className="shadowDrop bgWhite">
            <div className="container-fluid px-5 py-3">
                <div className="row align-items-end justify-content-end">
                    <div className="col-auto  mb-2">
                        <img className="iconMainMenu mx-4" src={iconBell} />
                        <img className="iconMainMenu mx-4" src={iconFaw} />
                        <img className="iconMainMenu mx-4" src={iconUser} />
                    </div>
                    <hr />
                </div>
                <div className="row align-items-start ">
                    <div className="col">
                        <h2 className="textBlack bold mb-0">{t("store.name")}</h2>
                        <div className=" d-flex align-items-center">
                            <img className="iconPinMenu m-1" src={IconPinGrad} />
                            <p className="textGrey regular my-0 mx-2">{t("store.address")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}



export function NavbarOnlyLogoSaga() {


    return (
        <div id="navbarOnlyLogoSaga">
            <div className="container-fluid  px-5 py-2">
                <div className="row justify-content-between">
                    <div className="col-auto">
                        <img className="img-icon80 mx-1" src={LogoSaga} />
                    </div> 
                    <div className="col-auto">
                    <div className="NavbarLangageselectorContainer">
                    </div>
                    </div>
                </div>
            </div>

        </div>
    );
}




import React, { useState } from 'react';
import "./cardrevenueforclient.css"
import IconClientGrad from "../../../../../img/IconClientGrad.png"
import IconStoreGreen from "../../../../../img/IconStoreGreen.png"
import IconEcommercePurple from "../../../../../img/IconEcommercePurple.png"
import { useTranslation } from 'react-i18next';


export function CardRevenueForClient({CardRevenueForClientData}) {
	const { t, i18n } = useTranslation();

    return (
        <div id="cardRevenueForClient" className="shadowDrop borderRadius bgWhite">
            <div className="p-2 p-xl-4 row">
                <div className="sectionRevenueForClient  col-md-4 d-flex flex-column align-items-start justify-content-end">
                    <img className="iconClientGrad mb-2" src={IconClientGrad} />
                    <h3 className="textBlack bold mb-0 txtsize-32">{t("CardRevenueForClient.title")}</h3>
                    <p className="textBlack regular mb-0 txtsize-14">{t("CardRevenueForClient.description")}</p>

                </div>
                <div className="sectionRevenueForClient px-3 col-md-4 d-flex align-items-start flex-column justify-content-center" >
                    <div className="d-flex flex-row my-4 justify-content-end align-items-end" >
                        <img className="iconStoreGreen me-2" src={IconStoreGreen} />
                        <h4 className="textGreen bold txtsize-21 mb-0"><span className="textBlack ">{t("CardRevenueForClient.yourstore")}</span>{t("CardRevenueForClient.store")}</h4>
                    </div>
                    <h4 className="textGreen semibold txtsize-16 text-uppercase mb-1 ">{t("CardRevenueForClient.revenueforclient")}</h4>
                    <div className="d-flex align-items-end flex-wrap " >
                        <h4 className=" textBlack semibold txtsize-24 mb-0 me-2 ">{CardRevenueForClientData.storeClient.toLocaleString(undefined, {minimumFractionDigits: 2})} €</h4>
                        <p className="textGrey regular txtsize-14 mb-0">{t("CardRevenueForClient.revenuepresence")}</p>

                    </div>
                    <div className="my-4 w-100" >
                    <div className="d-flex align-items-end h-100" >
                        <div className="borderRight d-flex flex-column   justify-content-end align-items-start h-100 w-50" >
                            <h4 className="textGreen semibold txtsize-16 text-uppercase mb-1 ">{t("CardRevenueForClient.tickets")}</h4>
                            <h4 className=" textBlack semibold txtsize-24 mb-0">{CardRevenueForClientData.ticket.toLocaleString()}</h4>

                        </div>
                        <div className="d-flex flex-column  justify-content-end align-items-start h-100  w-50  ps-3" >
                            <h4 className="textGreen semibold txtsize-16 text-uppercase mb-1">{t("CardRevenueForClient.presence")}</h4>
                            <h4 className=" textBlack semibold txtsize-24 mb-0">{CardRevenueForClientData.presence.toLocaleString()}</h4>
                        </div>
                        </div>
                    </div>
                    <p className="textBlack regular mb-0 txtsize-14"><span className="textGreen semibold txtsize-16">{CardRevenueForClientData.percentageStore.toLocaleString()}%</span> {t("CardRevenueForClient.storesubtitle")}</p>
                </div>
                
                <div className="sectionRevenueForClient px-3 col-md-4 d-flex align-items-start flex-column justify-content-center" >
                    <div className="d-flex flex-row my-4 justify-content-end align-items-end" >
                    <img className="iconEcommercePurple me-2" src={IconEcommercePurple} />
                        <h4 className=" textBlack bold txtsize-21 mb-0">{t("CardRevenueForClient.youronline")}<span className="textPurple bold txtsize-21 mb-0">{t("CardRevenueForClient.online")}</span></h4>
                    </div>
                    <h4 className="textPurple semibold txtsize-16 text-uppercase mb-1 ">{t("CardRevenueForClient.revenueforclient")}</h4>
                    <div className="d-flex align-items-end flex-wrap " >
                        <h4 className=" textBlack semibold txtsize-24 mb-0 me-2 ">{CardRevenueForClientData.onlineClient.toLocaleString(undefined, {minimumFractionDigits: 2})} €</h4>
                        <p className="textGrey regular txtsize-14 mb-0">{t("CardRevenueForClient.revenuevisit")}</p>

                    </div>
                    <div className="my-4 w-100" >
                    <div className="d-flex align-items-end h-100" >
                        <div className="borderRight d-flex flex-column   justify-content-end align-items-start h-100 w-50" >
                            <h4 className="textPurple semibold txtsize-16 text-uppercase mb-1 ">{t("CardRevenueForClient.transactions")}</h4>
                            <h4 className=" textBlack semibold txtsize-24 mb-0">{CardRevenueForClientData.transaction.toLocaleString()}</h4>

                        </div>
                        <div className="d-flex flex-column  justify-content-end align-items-start h-100  w-50  ps-3" >
                            <h4 className="textPurple semibold txtsize-16 text-uppercase mb-1">{t("CardRevenueForClient.uniquevisitors")}</h4>
                            <h4 className=" textBlack semibold txtsize-24 mb-0">{CardRevenueForClientData.unicClient.toLocaleString()}</h4>
                        </div>
                        </div>
                    </div>
                    <p className="textBlack regular mb-0 txtsize-14"><span className="textPurple semibold txtsize-16">{CardRevenueForClientData.percentageOnline.toLocaleString()}%</span>{t("CardRevenueForClient.onlinesubtitle")}</p>
                </div>
          

            </div>

        </div>
    );
}
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import {Dashboard} from "../pages/Dashboard";
 import {Login} from "../pages/Login";
// import NoPage from "../pages/NoPage";
 
export default function AppContainer() {
    return (
        <Router>
            <Switch>
                <Route path="/home">
                    <Dashboard />
                </Route>
                 <Route path="*" >
                    <Login />
                </Route>
              {/*  <Route path="*">
                    <NoPage />
                </Route> */}
            </Switch>
        </Router>
    );
}
import { TabComponent } from '@syncfusion/ej2-react-navigations';
import React, { useEffect } from 'react';
import { CardTargetDetails } from './cardstarget/CardTargetDetails'
import { CardTargetMainChart } from './cardstarget/CardTargetMainChart'
import { CardTargetStatList } from './cardstarget/CardTargetStatList'
import './tabtargetecommerce.css'
import IconPurpleMoney from '../../../img/IconPurpleMoney.png'
import IconPurpleShoppingCart from '../../../img/IconPurpleShoppingCart.png'
import IconPurpleUser from '../../../img/IconPurpleUser.png'
import IconPurpleTransaction from '../../../img/IconPurpleTransaction.png'
import IconPurpleTransactionAverage from '../../../img/IconPurpleTransactionAverage.png'
import IconPurpleSession from '../../../img/IconPurpleSession.png'
import IconPurpleMyStats from '../../../img/IconPurpleMyStats.png'
import IconPurpleFlag from '../../../img/IconPurpleFlag.png'
import IconBlackTicket from '../../../img/IconBlackTicket.png'
import IconBlackUser from '../../../img/IconBlackUser.png'
import { useTranslation } from 'react-i18next';


export function TabTargetEcommerce({ TabTargetEcommerceData, dateSelected }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.getElementById("ecommerceHeaderTabButtonRevenue").innerText = `${t("TabTargetEcommerce.revenue")}`
    document.getElementById("ecommerceHeaderTabButtonTransaction").innerText = `${t("TabTargetEcommerce.transaction")}`
    document.getElementById("ecommerceHeaderTabButtonTransactionAverage").innerText = `${t("TabTargetEcommerce.transactionaverage")}`
    document.getElementById("ecommerceHeaderTabButtonShoppingCart").innerText = `${t("TabTargetEcommerce.shoppingcart")}`
    document.getElementById("ecommerceHeaderTabButtonSession").innerText = `${t("TabTargetEcommerce.session")}`
    document.getElementById("ecommerceHeaderTabButtonUnicClients").innerText = `${t("TabTargetEcommerce.unicclients")}`
  }, [i18n.language])


  return (
    <TabComponent id='tabTargetEcommerce'>
      <div className="e-tab-header">


        <div>
          <div className="ecommerceHeaderTabButton mb-3 ">
            <span className="m-1 mb-0 reveneuTI"></span>
            <h1 id="ecommerceHeaderTabButtonRevenue" className="txtsize-12 regular mb-1 text-center mt-0"></h1>
          </div>
        </div>


        <div>
          <div className="ecommerceHeaderTabButton mb-3 ">
            <span className="m-1  mb-0 transactionTI"></span>
            <h1 id="ecommerceHeaderTabButtonTransaction" className="txtsize-12 regular mb-1 text-center mt-0"></h1>
          </div>
        </div>

        <div>
          <div className="ecommerceHeaderTabButton mb-3 ">
            <span className="m-1 mb-0 transactionAverageTI"></span>
            <h1 id="ecommerceHeaderTabButtonTransactionAverage" className="txtsize-12 regular mb-1 text-center mt-0"></h1>
          </div>
        </div>
        <div>
          <div className="ecommerceHeaderTabButton mb-3 ">
            <span className="m-1 mb-0 shoppingCartTI"></span>
            <h1 id="ecommerceHeaderTabButtonShoppingCart" className="txtsize-12 regular mb-1 text-center mt-0"></h1>
          </div>
        </div>



        <div>
          <div className="ecommerceHeaderTabButton mb-3 ">
            <span className="m-1 mb-0 sessionTI"></span>
            <h1 id="ecommerceHeaderTabButtonSession" className="txtsize-12 regular mb-1 text-center mt-0"></h1>
          </div>
        </div>



        <div>
          <div className="ecommerceHeaderTabButton mb-3 ">
            <span className="m-1 mb-0 userTI"></span>
            <h1 id="ecommerceHeaderTabButtonUnicClients" className="txtsize-12 regular mb-1 text-center mt-0"></h1>
          </div>
        </div>


      </div>
      <div className="e-content borderRadius px-0">
        {/* REVENEU */}
        <div>
          <CardTargetDetails
            Currency={true}
            Icon={IconPurpleMoney}
            PrimaryColor={"#DB91FF"}
            Title={t("TabTargetEcommerce.revenue")}
            Description={TabTargetEcommerceData.cardTargetDetails.reveneu} />

          <CardTargetMainChart
            dateSelected={dateSelected}
            id="reveneuEcommerce"
            Icon={null}
            IconFlag={IconPurpleFlag}
            PrimaryColor={"#DB91FF"}
            SecondaryColor={"#B558FD"}
            state={TabTargetEcommerceData.cardTargetReveneu.state}
            Title={TabTargetEcommerceData.cardTargetReveneu.nametarget}
            Description={t("TabTargetEcommerce.revenue")}
            min={TabTargetEcommerceData.cardTargetReveneu.min}
            value={TabTargetEcommerceData.cardTargetReveneu.value}
            max={TabTargetEcommerceData.cardTargetReveneu.max} />

          <CardTargetStatList
            Id="reveneuEcommerce"
            IconStat={IconPurpleMyStats}
            Currency={true}
            PrimaryColor={"#DB91FF"}
            SecondaryColor={"#B558FD"}
            headerTextFirst={t("CardTargetStatList.time")}
            headerTextSecond={t("CardTargetStatList.revenue")}
            data={TabTargetEcommerceData.cardTargetStatListReveneu.data}
          />
        </div>


        {/* TRANSACTION */}
        <div>
          <CardTargetDetails
            Currency={false}
            Icon={IconPurpleTransaction}
            PrimaryColor={"#DB91FF"}
            Title={t("TabTargetEcommerce.transaction")}
            Description={TabTargetEcommerceData.cardTargetDetails.transaction} />

          <CardTargetMainChart
            dateSelected={dateSelected}
            id="transactionEcommerce"
            Icon={IconPurpleTransaction}
            IconFlag={IconPurpleFlag}
            PrimaryColor={"#DB91FF"}
            SecondaryColor={"#B558FD"}
            state={TabTargetEcommerceData.cardTargetTransaction.state}
            Title={TabTargetEcommerceData.cardTargetTransaction.nametarget}
            Description={t("TabTargetEcommerce.transaction")}
            min={TabTargetEcommerceData.cardTargetTransaction.min}
            value={TabTargetEcommerceData.cardTargetTransaction.value}
            max={TabTargetEcommerceData.cardTargetTransaction.max} />

          <CardTargetStatList
            Id="transactionEcommerce"
            IconStat={IconPurpleMyStats}
            Currency={false}
            PrimaryColor={"#DB91FF"}
            SecondaryColor={"#B558FD"}
            headerTextFirst={t("CardTargetStatList.time")}
            headerTextSecond={t("CardTargetStatList.transaction")}
            data={TabTargetEcommerceData.cardTargetStatListTransaction.data}
          />

        </div>

        {/* TRANSACTION AVERAGE */}
        <div>
          <CardTargetDetails
            Currency={true}
            Icon={IconPurpleTransactionAverage}
            PrimaryColor={"#DB91FF"}
            Title={t("TabTargetEcommerce.transactionaverage")}
            Description={TabTargetEcommerceData.cardTargetDetails.transactionaverage} />

          <CardTargetMainChart
            dateSelected={dateSelected}
            id="transactionAverageEcommerce"
            Icon={null}
            IconFlag={IconPurpleFlag}
            PrimaryColor={"#DB91FF"}
            SecondaryColor={"#B558FD"}
            state={TabTargetEcommerceData.cardTargetTransactionAverage.state}
            Title={TabTargetEcommerceData.cardTargetTransactionAverage.nametarget}
            Description={t("TabTargetEcommerce.transactionaverage")}
            min={TabTargetEcommerceData.cardTargetTransactionAverage.min}
            value={TabTargetEcommerceData.cardTargetTransactionAverage.value}
            max={TabTargetEcommerceData.cardTargetTransactionAverage.max}
          />

          <CardTargetStatList
            Id="transactionAverageEcommerce"
            IconStat={IconPurpleMyStats}
            Currency={true}
            PrimaryColor={"#DB91FF"}
            SecondaryColor={"#B558FD"}
            headerTextFirst={t("CardTargetStatList.time")}
            headerTextSecond={t("CardTargetStatList.transactionaverage")}
            data={TabTargetEcommerceData.cardTargetStatListTransactionAverage.data}
          />

        </div>


        {/* SHOPPING Cart */}
        <div>
          <CardTargetDetails
            Currency={false}
            Icon={IconPurpleShoppingCart}
            PrimaryColor={"#DB91FF"}
            Title={t("TabTargetEcommerce.shoppingcart")}
            Description={TabTargetEcommerceData.cardTargetDetails.shoppingcart} />

          <CardTargetMainChart
            dateSelected={dateSelected}
            id="shoppingCartEcommerce"
            Icon={IconPurpleShoppingCart}
            IconFlag={IconPurpleFlag}
            PrimaryColor={"#DB91FF"}
            SecondaryColor={"#B558FD"}
            state={TabTargetEcommerceData.cardTargetTransactionAverage.state}
            Title={TabTargetEcommerceData.cardTargetTransactionAverage.nametarget}
            Description={t("TabTargetEcommerce.shoppingcart")}
            min={TabTargetEcommerceData.cardTargetTransactionAverage.min}
            value={TabTargetEcommerceData.cardTargetTransactionAverage.value}
            max={TabTargetEcommerceData.cardTargetTransactionAverage.max} />

          <CardTargetStatList
            Id="shoppingCartEcommerce"
            IconStat={IconPurpleMyStats}
            Currency={false}
            PrimaryColor={"#DB91FF"}
            SecondaryColor={"#B558FD"}
            headerTextFirst={t("CardTargetStatList.time")}
            headerTextSecond={t("CardTargetStatList.shoppingcart")}
            data={TabTargetEcommerceData.cardTargetStatListShoppingCart.data}
          />

        </div>


        {/* SESSION*/}
        <div>
          <CardTargetDetails
            Currency={false}
            Icon={IconPurpleSession}
            PrimaryColor={"#DB91FF"}
            Title={t("TabTargetEcommerce.session")}
            Description={TabTargetEcommerceData.cardTargetDetails.session} />

          <CardTargetMainChart
            dateSelected={dateSelected}
            id="sessionEcommerce"
            IconFlag={IconPurpleFlag}
            Icon={IconPurpleSession}
            PrimaryColor={"#DB91FF"}
            SecondaryColor={"#B558FD"}
            Description={t("TabTargetEcommerce.session")}
            state={TabTargetEcommerceData.cardTargetSession.state}
            Title={TabTargetEcommerceData.cardTargetSession.nametarget}
            min={TabTargetEcommerceData.cardTargetSession.min}
            value={TabTargetEcommerceData.cardTargetSession.value}
            max={TabTargetEcommerceData.cardTargetSession.max} />

          <CardTargetStatList
            Id="sessionEcommerce"
            IconStat={IconPurpleMyStats}
            Currency={false}
            PrimaryColor={"#DB91FF"}
            SecondaryColor={"#B558FD"}
            headerTextFirst={t("CardTargetStatList.time")}
            headerTextSecond={t("CardTargetStatList.session")}
            data={TabTargetEcommerceData.cardTargetStatListSession.data}
          />

        </div>


        {/* User */}
        <div>
          <CardTargetDetails
            Currency={false}
            Icon={IconPurpleUser}
            PrimaryColor={"#DB91FF"}
            Title={t("TabTargetEcommerce.unicclients")}
            Description={TabTargetEcommerceData.cardTargetDetails.user} />

          <CardTargetMainChart
            dateSelected={dateSelected}
            id="userEcommerce"
            Icon={IconPurpleUser}
            IconFlag={IconPurpleFlag}
            PrimaryColor={"#DB91FF"}
            SecondaryColor={"#B558FD"}
            Description={t("TabTargetEcommerce.unicclients")}
            state={TabTargetEcommerceData.cardTargetUser.state}
            Title={TabTargetEcommerceData.cardTargetUser.nametarget}
            min={TabTargetEcommerceData.cardTargetUser.min}
            value={TabTargetEcommerceData.cardTargetUser.value}
            max={TabTargetEcommerceData.cardTargetUser.max} />

          <CardTargetStatList
            Id="userEcommerce"
            IconStat={IconPurpleMyStats}
            Currency={false}
            PrimaryColor={"#DB91FF"}
            SecondaryColor={"#B558FD"}
            headerTextFirst={t("CardTargetStatList.time")}
            headerTextSecond={t("CardTargetStatList.unicclients")}
            data={TabTargetEcommerceData.cardTargetStatListUser.data}
          />

        </div>


        <div>
          <div>
          </div>
        </div>
      </div>
    </TabComponent>
  );
}

import { Category, ChartComponent, ColumnSeries, Inject, Tooltip, LineSeries, SeriesCollectionDirective, SeriesDirective } from '@syncfusion/ej2-react-charts';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import "./linechartcard.css"


export function LineChartCard({ Title, PrimaryColor, SecondaryColor, Id, Icon, IconTime, data }) {
    const { t, i18n } = useTranslation();

    // MARKER (the circle on the line)
    const marker = { visible: true, width: 10, height: 10 };

    // TOOLTIP
    // const tooltipTemplate = linechartTooltipTemplate;
    const tooltip = {
        enable: true
    }

    const tootltipStyle = `path#${'chartLine' + Id}_tooltip_path{
        fill: #d6d6d699;
        stroke: none;
    }
    #${'chartLine' + Id}_Secondary_Element text#${'chartLine' + Id}_tooltip_text tspan:nth-last-child(1){
    font-weight: bold;
        fill:#363636;
    } 
    
     #${'chartLine' + Id}_Secondary_Element text#${'chartLine' + Id}_tooltip_text tspan{
        font-weight: bold;
            fill:${SecondaryColor};
        }
    // g#${'chartLine' + Id}_tooltip_trackball_group {display: none;}
    `




    // const dataTranslate = () => {
    //     for (const [key, value] of Object.entries(data)) {
    //         if (key === "Lun") {
    //             myObj[prop] = value;
    //             value === "Pippo"
    //         }
    //         console.log(`${key}: ${value}`);
    //     }
    // }
    // useEffect(() => {

    // }, [i18n.language])

    return (
        <>
            <style>
                {tootltipStyle}
            </style>
            <div id={'lineChartCard' + Id}
                className='p-2 my-2 borderRadius bgWhite shadowDrop'>
                <div className='d-flex flex-row m-1 align-items-center'>
                    <img src={Icon} className='img-icon30 m-2' />
                    <h3 className='textBlack bold txtsize-24 mb-0'>{t(Title)}</h3>
                </div>
                <div className='mx-4'>
                    <ChartComponent
                        id={'chartLine' + Id}
                        className="overflowChart"
                        primaryXAxis={{
                            valueType: 'Category',
                            interval: 1,
                            majorGridLines: { width: 0 },
                            labelIntersectAction: 'MultipleRows',
                            labelPlacement: 'OnTicks'
                        }}
                        tooltip={{
                            enable: true, header: '${point.x}',
                            format: '${point.y}'
                        }}
                        chartArea={{ border: { width: 0 } }}
                    >
                        <Inject services={[ColumnSeries, LineSeries, Tooltip, Category]} />
                        <SeriesCollectionDirective>
                            <SeriesDirective
                                fill={`url(#gradientes${Id})`}
                                width="100%"
                                dataSource={data}
                                xName='time'
                                yName='sales'
                                name='Sales'
                                width={4} type='Line'
                                marker={marker}
                            />
                        </SeriesCollectionDirective>
                    </ChartComponent>

                    <svg width="0" height="0">
                        <defs>
                            <linearGradient id={"gradientes" + Id} gradientTransform="rotate(20)">
                                <stop offset="0" stopColor={PrimaryColor}></stop>
                                <stop offset="100%" stopColor={SecondaryColor} ></stop>
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>
        </>
    );
}






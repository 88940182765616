import React, { useState } from 'react';
import "./cardtotalturnover.css"
import { PieChart } from "../../../../cardComponents/common/charts/PieChart"
import IconGradTransaction from "../../../../../img/IconGradTransaction.png"
import IconStoreGreen from "../../../../../img/IconStoreGreen.png"
import IconEcommercePurple from "../../../../../img/IconEcommercePurple.png"
import { useTranslation } from 'react-i18next';

export function CardTotalTransaction({CardTotalTransactionData}) {
	const { t, i18n } = useTranslation();
    return (
        <div id="cardTotalTransaction" className="p-4 bgWhite shadowDrop borderRadius h-100">
            <div className=" d-flex flex-wrap   ">
                <div className="col-md-6">
                    <div className="d-flex align-items-end">
                        <img className="iconTotalMoney me-2" src={IconGradTransaction} />
                        <h3 className="textBlack bold mb-0 txtsize-21">{t("CardTotalTurnover.title")}</h3>
                    </div>
                    <h2 className="textGrey bold my-4 txtsize-32">{CardTotalTransactionData.total.toLocaleString()}</h2>
                    <div className="d-flex align-items-center mb-4" >
                        <img className="iconStoreGreen me-2" src={IconStoreGreen} />
                        <div className="d-flex flex-column" >
                            <h4 className="textGreen semibold txtsize-18 mb-0">{t("CardTotalTurnover.store")}</h4>
                            <p className="textBlack regular txtsize-18 mb-0">{CardTotalTransactionData.store.toLocaleString()}</p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-1" >
                        <img className="iconEcommercePurple me-2" src={IconEcommercePurple} />
                        <div className="d-flex flex-column" >
                            <h4 className="textPurple semibold txtsize-18 mb-0">{t("CardTotalTurnover.ecommerce")}</h4>
                            <p className="textBlack regular txtsize-18 mb-0">{CardTotalTransactionData.online.toLocaleString()}</p>
                        </div>
                    </div>
                    
                </div>
                <div className="pieChartContent">
                    <PieChart 
                     id="TotalTransactionPie"
                     PiechartData={CardTotalTransactionData.pieChart}/>
                    <p className="textBlack text-center regular txtsize-14 mb-0">{t("CardTotalTurnover.subtitle")} <strong> {CardTotalTransactionData.ticket.toLocaleString(undefined, {minimumFractionDigits: 2})} €</strong></p>
                </div>
            </div>
        </div>
    );
}
import { TabComponent } from '@syncfusion/ej2-react-navigations';
import React, { useState, useEffect } from 'react';
import { CardTargetMainChart } from './cardstarget/CardTargetMainChart'
import { CardTargetStatList } from './cardstarget/CardTargetStatList'
import './tabtargetattendance.css'
import IconAzureMyStats from '../../../img/IconAzureMyStats.png'
import IconAzureFlag from '../../../img/IconAzureFlag.png'
import IconAzureUser from '../../../img/IconAzureUser.png'
import IconBlackUser from '../../../img/IconBlackUser.png'
import { useTranslation } from 'react-i18next';

export function TabTargetAttendance({ dateSelected, tabTargetAttendanceData }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.getElementById("attendanceHeaderTabButtonDailyPresence").innerText = `${t("TabTargetAttendance.dailypresence")}`
  }, [i18n.language])

  return (
    <TabComponent id='tabTargetAttendance'>
      <div className="e-tab-header">
        <div >
          <div className="attendanceHeaderTabButton mb-1 mx-3">
            <div className="d-flex flex-row align-items-center">
              <h1 id="attendanceHeaderTabButtonDailyPresence" className="txtsize-16 regular mb-1 text-center mt-0">
              </h1><span className="m-1 mb-0 dailyAttendaceTI"></span>
            </div>
          </div>
        </div>
      </div>
      <div className="e-content borderRadius  px-2">
        {/* total daily attendance  */}
        <div>
          <CardTargetMainChart
            dateSelected={dateSelected}
            id="dailyAttendance"
            Icon={IconAzureUser}
            IconFlag={IconAzureFlag}
            PrimaryColor={"#8db9ff"}
            SecondaryColor={"#7789ff"}
            Title={tabTargetAttendanceData.cardTargetDailyAttendance.nametarget}
            state={tabTargetAttendanceData.cardTargetDailyAttendance.state}
            Description={t("TabTargetAttendance.presence")}
            min={tabTargetAttendanceData.cardTargetDailyAttendance.min}
            max={tabTargetAttendanceData.cardTargetDailyAttendance.max}
            value={tabTargetAttendanceData.cardTargetDailyAttendance.value}
          />

           <CardTargetStatList
            Id="dailyAttendance"
            IconStat={IconAzureMyStats}
            Currency={false}
            PrimaryColor={"#8db9ff"}
            SecondaryColor={"#7789ff"}
            headerTextFirst={t("CardTargetStatList.time")}
            headerTextSecond={t("CardTargetStatList.people")}
            data={tabTargetAttendanceData.cardTargetStatListDailyAttendance.data} /> 

        </div>
        <div>
          <div>
          </div>
        </div>
      </div>
    </TabComponent>
  );
}

import React, { useState } from 'react';
import { CardTotalTurnover } from '../../tabContainer/pages/overviewCards/CardTotalTurnover'
import { CardRevenueForClient } from '../../tabContainer/pages/overviewCards/CardRevenueForClient'
import { CardLinearTrafficComparison } from './overviewCards/CardLinearTrafficComparison'
import { CardLastTarget } from '../../tabContainer/pages/overviewCards/CardLastTarget'
import { CardTotalTransaction } from './overviewCards/CardTotalTransaction'
import { useTranslation } from 'react-i18next';

export function Overview({overviewData,dateSelected}) {
	const { t, i18n } = useTranslation();

  return (
    <div className="container-fluid pb-4">
      <div className="row p-1">
        <div className="my-2 col-12">
          <h2 className="textBlack txtsize-18 regular mb-0">
            <span className="textBlack txtsize-21 bold ">{t("Overview.nb")}</span> {t("Overview.note")}</h2>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-6 p-2">
          <CardTotalTurnover 
          id={"totalTurnover"}
          CardTotalTurnoverData ={overviewData.totalTurnoverCard}
           />
        </div>
        <div className="col-md-6 p-2">
          <CardTotalTransaction
          id={"totalTransactions"}
          CardTotalTransactionData ={overviewData.totalTransactionCard}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-12 p-2">
          <CardLastTarget 
          dateSelected={dateSelected}
           CardLastTargetData ={overviewData.targetCard}/>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-12 p-2">
          <CardRevenueForClient 
            CardRevenueForClientData={overviewData.clientCard} 
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-12 p-2">
          <CardLinearTrafficComparison 
          id="overviewTrafficComparison" 
          data ={overviewData.trafficComparision.data}/>
        </div>
      </div>
    </div>


  );
}






import React, { useState, useEffect, useRef } from 'react';
import "./dateinputgroup.css"
import * as moment from "moment";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { useTranslation } from 'react-i18next';
import { loadCldr } from '@syncfusion/ej2-base';

loadCldr(
		require('cldr-data/supplemental/numberingSystems.json'),
		require('cldr-data/supplemental/weekData.json'),
		require('cldr-data/main/fr-CH/ca-gregorian.json'),
		require('cldr-data/main/fr-CH/numbers.json'),
		require('cldr-data/main/fr-CH/timeZoneNames.json'),
		require('cldr-data/main/en-CH/ca-gregorian.json'),
		require('cldr-data/main/en-CH/numbers.json'),
		require('cldr-data/main/en-CH/timeZoneNames.json'),
		require('cldr-data/main/it-CH/ca-gregorian.json'),
		require('cldr-data/main/it-CH/numbers.json'),
		require('cldr-data/main/it-CH/timeZoneNames.json')
	);
export function DateInputGroup({ parentCallbackDateArr, parentCallbackDateType, props }) {
	const { t, i18n } = useTranslation();
	/* const [dataRange, setDataRange] = useState(dataRangeDate); */
	const [typeDate, setTypeDate] = useState("Day")
	const [startDate, setStartDate] = useState("Day")
	const [depthDate, setDepthDate] = useState("Day")
	const [formatDate, setFormatDate] = useState("dd MMMM y")
	const [multiSelectionDate, setMultiSelectionDate] = useState("false")
	const [valueDate, setValueDate] = useState(new Date())
	const [valuesDate, setValuesDate] = useState([])
	const [lenguageDate, setLenguageDate] = useState()
	// const [loading, setLoading] = useState(["none"])


	useEffect(() => { setValueDate(new Date()) }, [formatDate])



	const inputEl = useRef(null);


	useEffect(() => {
		setLenguageDate(i18n.language+"-CH")
	}, [])

	useEffect(() => {
		setLenguageDate(i18n.language+"-CH")
		//inputEl.locale = "it-CH"
	}, [i18n.language])


	const setRadioDateSelect = (event) => {
		//calendarDatePicker.current.values = new Date();

		switch (event.target.value) {
			case "Day":
				setTypeDate("Day")
				setStartDate("Day")
				setDepthDate("Day")
				setFormatDate("dd MMMM y")
				setMultiSelectionDate(false)
				parentCallbackDateArr(new Date(), new Date());
				parentCallbackDateType("Day");
				break;
			case "Week":
				setTypeDate("Week")
				setStartDate("Day")
				setDepthDate("Day")
				setFormatDate("dd MMMM y")
				setMultiSelectionDate(true)
				const startOfWeek = moment(new Date()).startOf('week');
				const endOfWeek = moment(new Date()).endOf('week');
				getWeekArray(startOfWeek, endOfWeek);
				parentCallbackDateType("Week");

				break;
			case "Month":
				setTypeDate("Month")
				setStartDate("Year")
				setDepthDate("Year")
				setFormatDate("MMMM")
				setMultiSelectionDate(false)
				var y = new Date().getFullYear()
				var m = new Date().getMonth();
				var firstDay = new Date(y, m, 1);
				var lastDay = new Date(y, m + 1, 0);
				// PARENT CALLBACK
				parentCallbackDateArr(firstDay, lastDay);
				parentCallbackDateType("Month");


				break;
			case "Year":
				setTypeDate("Year")
				setStartDate("Decade")
				setDepthDate("Decade")
				setFormatDate("y")
				setMultiSelectionDate(false)
				break;
			default:
		}

	}



	const handleChangeDataPicker = (event) => {
		setValuesDate([])
		/* const dateSelected = { type: dataRange.type, date: event.element.value } */
		if (typeDate == "Week") {
			const startOfWeek = moment(event.value).startOf('week');
			const endOfWeek = moment(event.value).endOf('week');
			getWeekArray(startOfWeek, endOfWeek);
			setValueDate(event.value)

		} else if (typeDate == "Month") {
			setValueDate(event.value)
			
			var y = event.value.getFullYear()
			var m = event.value.getMonth();
			var firstDay = new Date(y, m, 1);
			var lastDay = new Date(y, m + 1, 0);
			// PARENT CALLBACK
			parentCallbackDateArr(firstDay, lastDay);

		}
		else {
			setValueDate(event.value)
			// PARENT CALLBACK
			parentCallbackDateArr(new Date(event.value), new Date(event.value));

		}
	
	}


	const getWeekArray = (startOfWeek, endOfWeek) => {

		const days = [];
		let day = startOfWeek;
		while (day <= endOfWeek) {
			days.push(day.toDate());
			day = day.clone().add(1, 'd');
		}
		setValuesDate(days)
		// PARENT CALLBACK
		parentCallbackDateArr(new Date(startOfWeek), new Date(endOfWeek));
	}

	return (
		<div id="dateSelector" className='borderRadius shadowDrop p-3 h-100 d-flex justify-content-around flex-wrap'>
			{/* BUTTON RADIO */}
			<div id="dateInputGroup" className="align-self-center" >
				<div className="stv-radio-buttons-wrapper borderRadius d-flex" onChange={setRadioDateSelect}>
					<div className="buttonRadio">
						<input type="radio" className="stv-radio-button" name="radioDateSelect"
							defaultChecked
							value="Day"
							id="button1" />
						<label htmlFor="button1">{t("datepicker.day")}</label>
					</div>
					<div className="buttonRadio">
						<input type="radio" className="stv-radio-button" name="radioDateSelect"
							value="Week"
							id="button2" />
						<label htmlFor="button2">{t("datepicker.week")}</label>
					</div>
					<div className="buttonRadio">
						<input type="radio" className="stv-radio-button" name="radioDateSelect"
							value="Month"
							id="button3" />
						<label htmlFor="button3">{t("datepicker.month")}</label>
					</div>
					<div className="buttonRadio">
						<input type="radio" className="stv-radio-button" name="radioDateSelect"
							value="Year"
							id="button4" />
						<label htmlFor="button4">{t("datepicker.year")}</label>
					</div>
				</div>
			</div>
			{/* DATE PICKER */}

			<div className='align-self-center'>
				<div className='control-section'>
					<div className='datepicker-control-section'>
						<DatePickerComponent
						//	ref={inputEl}
							change={handleChangeDataPicker}
							id="calendar"
							value={valueDate}
							values={valuesDate}
							isMultiSelection={multiSelectionDate}
							start={startDate}
							depth={depthDate}
							format={formatDate}
							locale = {lenguageDate}
						>
						</DatePickerComponent >
					</div>
				</div>
			</div>
			{/* <p style={{display:loading}}>loading...</p> */}
		</div>
	);
}


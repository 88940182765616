import React, { useState } from 'react';

import { TabTargetStore } from '../../../cardComponents/cardtabtarget/TabTargetStore'
import { SummaryCardStore } from '../pages/storeCards/SummaryCardStore'
import { BarChartCard } from '../pages/commonCards/BarChartCard'
import { LineChartCard } from '../pages/commonCards/LineChartCard'
import IconGreenTrendDaily from '../../../../img/IconGreenTrendDaily.png'
import IconGreenTrendHour from '../../../../img/IconGreenTrendHour.png'
import IconGreenClock from '../../../../img/IconGreenClock.png'
import { useTranslation } from 'react-i18next';

export function Store({ storeData, dateSelected,dateType }) {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="row p-3 ">
        <div className="ms-3 col-12">
          <h2 className="textBlack txtsize-18 regular">
            <span className="textBlack txtsize-21 bold">{t("Store.nb")}</span> {t("Store.note")}
          </h2>
        </div>
      </div>
      <div className="row p-3">
        <div className=" col-md-6 col-xl-8">
          <SummaryCardStore
            summaryCardStoreData={storeData.summaryCardStore}
          />

          <LineChartCard
             Title={"LineChartCard.title"}
            Id="StoreLine"
            PrimaryColor={"#53E3BA"}
            SecondaryColor={"#2FCD91"}
            Icon={IconGreenTrendDaily}
            IconTime={IconGreenClock}
            data={storeData.lineChartCard.data} />
          <BarChartCard
           TitleDay="BarChartCard.titleday"
           TitleWeek="BarChartCard.titleweek"
           TitleMonth="BarChartCard.titlemonth"
          dateType={dateType}
            Id="StoreBar"
            PrimaryColor={"#53E3BA"}
            SecondaryColor={"#2FCD91"}
            Icon={IconGreenTrendHour}
            data={storeData.barChartCard.data}
          />
        </div>
        
        <div className="col-md-6 col-xl-4 bgGreyTrasparent borderRadius">
          <TabTargetStore
           dateType={dateType}
           dateSelected={dateSelected}
            tabTargetStoreData={storeData.tabTargetStore} />
        </div>
      </div>
    </div>


  );
}






import React, { useState, useEffect } from 'react';
import { ProgressBarComponent, ProgressBarAnnotationsDirective, ProgressBarAnnotationDirective, Inject, ProgressAnnotation } from '@syncfusion/ej2-react-progressbar';
import { useTranslation } from 'react-i18next';
import "./cardtargetmainchart.css"


export function CardTargetMainChartProgress({ dateSelected, id, IconFlag, Icon, Title, Description, PrimaryColor, SecondaryColor, min, max, value }) {
    const [numberAfterComma, setNumberAfterComma] = useState(undefined)

    useEffect(() => {
        if (Icon) {
            setNumberAfterComma(undefined)
        } else {
            setNumberAfterComma(2)
        }

    }, [])


    const content1 = `<div id= "${"targetChart" + id}" style="width: max-content;" ><p style="color:${PrimaryColor}" class="text-uppercase txtsize-16 bold mb-0">Nome obiettivo</p>
    <p class="txtsize-16 bold mb-0">${Title}</p></div>`;
    const { t, i18n } = useTranslation();

    return (
        <>
            <div id="cardTargetMainChartProgress" className="borderRadius bgWhite mx-2 my-3 p-4 minHeightCard">
                <div className="d-flex  align-items-center ">
                    <img className=" m-2 img-icon20" src={IconFlag} />
                    <h3 className="textBlack txtsize-24 bold mb-0" >{t("CardTargetMainChartProgress.mytarget")}</h3>
                </div>
                <p className="textGrey txtsize-16 regular m-2 mt-0 mb-0">{Description}</p>
                <div>
                </div>
                <div >
                    <div className="mt-3 text-center">
                        <div className="row">
                            <div className="col-12 ">
                                <div style={{ position: "relative" }}>
                                    <div id={"targetChart" + id}
                                        style={{
                                            width: "190px", position: "absolute", zIndex: 1000,
                                            top: "106px", margin: "auto", left: 0, right: 0
                                        }} >
                                        <p style={{ color: PrimaryColor }}
                                            className="text-uppercase txtsize-16 bold mb-0">{t("CardTargetMainChartSuccess.nametarget")}</p>
                                        <p style={{ overflowWrap: "break-word" }} className="txtsize-16 bold mb-0">{Title}</p></div>
                                    <ProgressBarComponent
                                        id={"targetChart" + id}
                                        progressColor={` url(#gradient${id})`}
                                        type='Circular'
                                        startAngle={210}
                                        endAngle={150}
                                        width='260px'
                                        height='260px'
                                        minimum={min}
                                        maximum={max}
                                        value={value}
                                        cornerRadius='Round'
                                        trackThickness={24}
                                        progressThickness={18}
                                        animation={{
                                            enable: false,
                                            duration: 2000,
                                            delay: 0,
                                        }}
                                    >
                                        <Inject services={[ProgressAnnotation]} />
                                        <ProgressBarAnnotationsDirective>
                                            <ProgressBarAnnotationDirective
                                            // content={content1}
                                            >
                                            </ProgressBarAnnotationDirective>
                                        </ProgressBarAnnotationsDirective>
                                    </ProgressBarComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex  align-items-center justify-content-center">
                    <p style={{ color: PrimaryColor }} className={" txtsize-32 regular text-uppercase ms-2 m-1 text-center"} >
                        <span className="textBlack txtsize-32 bold m-1 d-inline-block" >
                            {value.toLocaleString(undefined, { minimumFractionDigits: numberAfterComma })} / </span>
                        <span className="d-inline-block">  {max.toLocaleString(undefined, { minimumFractionDigits: numberAfterComma })}{Icon ? <img className="img-icon20 align-middle" src={Icon} /> : "€"}</span>
                    </p>
                </div>
                <div className="d-flex  align-items-center justify-content-center">
                    <div className="d-flex  align-items-center flex-wrap">
                        <p style={{ color: PrimaryColor }} className={" txtsize-14 bold text-uppercase ms-2 m-0"}>{t("CardTargetMainChartProgress.start")}</p>
                        <p className="textBlack txtsize-14 regular m-2 mt-0 mb-0">{dateSelected.startDate.toLocaleString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })}</p>
                    </div>
                    <div className="d-flex  align-items-center flex-wrap">
                        <p style={{ color: PrimaryColor }} className={" txtsize-14  bold text-uppercase ms-2 m-0"}>{t("CardTargetMainChartProgress.end")}</p>
                        <p className="textBlack txtsize-14 regular m-2 mt-0 mb-0">{dateSelected.endDate.toLocaleString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })}</p>
                    </div>
                </div>
                <svg width="0" height="0">
                    <defs>
                        <linearGradient id={"gradient" + id}>
                            <stop offset="0" stopColor={PrimaryColor}></stop>
                            <stop offset="100%" stopColor={SecondaryColor} ></stop>
                        </linearGradient>
                    </defs>
                </svg>
            </div>



        </>
    );
}


import React, { useState } from 'react';
import { CardTargetMainChartProgress } from './CardTargetMainChartProgress'
import { CardTargetMainChartSuccess } from './CardTargetMainChartSuccess'
import { CardTargetMainChartStartNew } from './CardTargetMainChartStartNew'


export function CardTargetMainChart({ dateSelected, id, IconFlag, Icon, Title, Description, PrimaryColor, SecondaryColor, state, min, max, value }) {


    if (state == "progress") {
        return (
            <>
                <CardTargetMainChartProgress
                    dateSelected={dateSelected}
                    id={id}
                    IconFlag={IconFlag}
                    Icon={Icon}
                    Title={Title}
                    Description={Description}
                    PrimaryColor={PrimaryColor}
                    SecondaryColor={SecondaryColor}
                    min={min}
                    max={max}
                    value={value} />  
            </>
        );
    } else if (state == "success") {
        return (
            <>
                <CardTargetMainChartSuccess
                    dateSelected={dateSelected}
                    IconFlag={IconFlag}
                    Icon={Icon}
                    Title={Title}
                    Description={Description}
                    PrimaryColor={PrimaryColor}
                    SecondaryColor={SecondaryColor}
                    max={max}
                    value={value} />
            </>
        );
    } else if (state == "startNew") {
        return (
            <>
                <CardTargetMainChartStartNew
                    IconFlag={IconFlag}
                    PrimaryColor={PrimaryColor}
                    SecondaryColor={SecondaryColor} />
            </>
        );
    }
}


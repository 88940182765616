import { Category, ChartComponent, ColumnSeries, AxesDirective, AxisDirective, Tooltip, DataLabel, Inject, LineSeries, SeriesCollectionDirective, SeriesDirective } from '@syncfusion/ej2-react-charts';
import React, { useState } from 'react';
import IconGradLineChart from "../../../../../img/IconGradLineChart.png"

import IconAzureClock from '../../../../../img/IconAzureClock.png'
import IconPurpleClock from '../../../../../img/IconPurpleClock.png'
import { useTranslation } from 'react-i18next';
import "./cardlineartrafficcomparison.css"

export function CardLinearTrafficComparison({ id, data }) {
  const { t, i18n } = useTranslation();


  const marker = { visible: true, width: 10, height: 10 };

  

  // TOOLTIP

  const tooltip = {
    enable: true
  }
  // // TOOLTIP template
  // function linechartTooltipTemplate(args) {

  //   return (
  //     <div id="lineChartTooltip" className="p-2 borderRadius" >
  //       <div className="d-flex tooltipHeader  align-items-center">

  //         {/* <img className="iconStore" src={args.x === 'store' ? IconStoreGreen : IconEcommerce} /> */}
  //         <p className="regular txtsize-16 ms-2 mb-0"><img className="img-icon15 m-1" src={args.name == "trafficStore" ? IconAzureClock : IconAzureClock} /><span style={{ color: "red" }}>{args.x}</span></p>
  //       </div>
  //       <div>
  //         <p className="textBlack bold text-center txtsize-18 ms-2 mb-0">{args.y}€</p>
  //       </div>
  //     </div>);
  // }
 // const tooltipRender = (args) => {
    // if (args.point.index === 3) {
    //     args.text = args.point.x + '' + ':' + args.point.y + '' + ' ' + 'customtext';
    //    // args.textStyle.color = '#242424';
    // }


  //};

  // const [sata, setData] = useState(data);



  function maxY(input) {
    let arrYData = []
    input.map(data => arrYData.push(data.y))

    if (toString.call(arrYData) !== "[object Array]") {
      return false;
    } else {
      let highestValue = Math.max.apply(null, arrYData)
      while ((highestValue % (6 *10))!== 0) {
        highestValue++
      }
      return highestValue
    }
  }

  function maxY2(input) {
    let arrYData = []
    input.map(data => arrYData.push(data.y2))

    if (toString.call(arrYData) !== "[object Array]") {
      return false;
    } else {
      let highestValue = Math.max.apply(null, arrYData)
      while ((highestValue % (6 *10)) !== 0) {
        highestValue++
      }
      return highestValue
    }
  }

  return (
    <div className='p-2 my-2  borderRadius bgWhite shadowDrop'>
      <div className="d-flex align-items-end m-2 p-3 ">
        <img src={IconGradLineChart} className="img-icon30 m-1 me-2" />
        <div className="d-flex flex-column">
          <h3 className='textBlack bold  mb-0' >{t("CardLinearTrafficComparison.title")}</h3>
          <p className='textBlack mb-0' >{t("CardLinearTrafficComparison.description")}</p>
        </div>
      </div>
      <div className='mx-4'>
        <ChartComponent
          tooltip={{
            enable: true, header: '${point.x}',
            format: '${point.y}'
          }
          }
          id={id}
          className="overflowChart"
          labelPlacement={"Auto"}
          primaryXAxis={{
            valueType: 'Category',
            interval: 1,
            majorGridLines: { width: 0 },
            labelPlacement: 'OnTicks'
          }}
          chartArea={{ border: { width: 0 } }}
          >
          <Inject services={[LineSeries, Tooltip, DataLabel, Category]} />
          <AxesDirective>
            <AxisDirective
              majorGridLines={{ width: 0 }}
              // rowIndex={0}
              minimum={0}
              maximum={maxY(data)}
              interval={maxY(data) / 6}
              opposedPosition={false}
              lineStyle={{ width: 0 }}
              majorTickLines={{ width: 0 }}
              name='yAxis0' >
            </AxisDirective>
            <AxisDirective
              // majorGridLines={{ width: 0 }}
              // rowIndex={0}
              minimum={0}
              maximum={maxY2(data)}
              interval={maxY2(data) / 6}
              opposedPosition={true}
              lineStyle={{ width: 0 }}
              majorTickLines={{ width: 0 }}
              name='yAxis1' >
            </AxisDirective>
          </AxesDirective>
          <SeriesCollectionDirective  >
            <SeriesDirective
              yAxisName='yAxis0'
              dataSource={data}
              xName='x'
              yName='y'
              width={4}
              type='Line'
              fill={`url(#gradientes1${id})`}
              marker={marker} >
            </SeriesDirective>

            {/* <SeriesDirective dataSource={data2} xName='x' yName='y' name='Japan' type='Line' marker={{
            visible: true, width: 10, height: 10, border: { width: 2, color: '#F8AB1D' }
        }} yAxisName='yAxis1' width={2}> */}


            <SeriesDirective
              dataSource={data}
              xName='x'
              yName='y2'
              width={4}
              type='Line'
              fill={`url(#gradientes2${id})`}
              marker={marker}
              yAxisName='yAxis1'>
            </SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
        <div className="d-flex justify-content-center mt-3">
          <h3 className="textBlack txtsize-16 bold d-flex align-items-center  mx-4" >
            <span className="legendComparisonStore mx-2"></span>{t("CardLinearTrafficComparison.legendOne")}</h3>
          <h3 className="textBlack txtsize-16 bold d-flex align-items-center  mx-4" >
            <span className="legendComparisonOnline mx-2"></span>{t("CardLinearTrafficComparison.legendTwo")}</h3>
        </div>
        <svg width="0" height="0">
          <defs>
            <linearGradient id={"gradientes1" + id} gradientTransform="rotate(20)">
              <stop offset="0" stopColor={"#DA90FF"}></stop>
              <stop offset="100%" stopColor={"#B558FD"} ></stop>
            </linearGradient>
            <linearGradient id={"gradientes2" + id} gradientTransform="rotate(20)">
              <stop offset="0" stopColor={"#98D2FF"}></stop>
              <stop offset="100%" stopColor={"#7481FF"} ></stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
}






import React, { useState } from 'react';
import "./sidebars.css"
//IMAGE AND ICON
import LogoSaga from "../../../img/LogoSaga.png"
import IconPlusAddStore from "../../../img/IconPlusAddStore.png"
import { LanguageSelector } from '../LanguageSelector';
import { useTranslation } from 'react-i18next';

export function SideBar() {
    const { t, i18n } = useTranslation();


    const nameStore = t("store.name")

    return (
        <div id="sideBar">
            <div className="innerContainerSideBar">
                <img className="logoSaga" src={LogoSaga} />
                <div className="StoreSelect">
                    <button className="squareStoreSelect shadowDrop">
                        <p className="text-uppercase bold m-0 textWhite txtsize-21">{t("store.name").charAt(0)}</p>
                    </button>
                    <p className="nameStore">{t("store.name")}</p>
                </div>
                <img className="IconPlusAddStore" src={IconPlusAddStore} />
                <LanguageSelector />
            </div>

        </div>
    );
}


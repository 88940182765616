import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './selecthoursbuttons.css'

export function SelectHoursButtons({ PrimaryColor, SecondaryColor, Id, data, callbackTimeSelected }) {
    const { t, i18n } = useTranslation();


    const setRadioTimeSelect = (event) => {
        callbackTimeSelected(Object.values(data)[event.target.value])

    }



    const lineGradUnderLabelStyle = `#${"selectHours" + Id} .shb-radio-button:checked  + .lineGradUnderLabel:after {
                                        background: -webkit-linear-gradient(180deg, ${PrimaryColor} 0%, ${SecondaryColor} 100%);
                                        border-radius: 20px;
                                        padding:0 5px;
                                    }
                                            #${"selectHours" + Id} .shb-radio-button:checked + .textGradNeutro{
                                                background: -webkit-linear-gradient(180deg, ${PrimaryColor} 0%, ${SecondaryColor} 100%);
                                                background-clip: text;
                                                -webkit-background-clip: text;
                                                -webkit-text-fill-color: transparent;
                                              width: 100%;
                                              }`
    return (
        <>
            <style>
                {lineGradUnderLabelStyle}
            </style>
            {/* BUTTON RADIO */}
            <div className="p-2 " id={"selectHours" + Id} >
                <p className="textBlack regular txtsize-12 my-1 mx-0">{t("HourFilterForDay.showinterval")}</p>
                <div className="d-flex" onChange={setRadioTimeSelect}>
                    <div className="me-4">
                        <input type="radio"
                            className="shb-radio-button"
                            name={"radioSelect" + Id}
                            defaultChecked
                            value={0}
                            id={"button1" + Id} />
                        <label htmlFor={"button1" + Id}
                            className="textGradNeutro lineGradUnderLabel text-center bold textBlack txtsize-14 "
                        >{t("HourFilterForDay.onehour")}</label>

                    </div>
                    <div className="me-4">
                        <input type="radio"
                            className="shb-radio-button"
                            name={"radioSelect" + Id}
                            value={1}
                            id={"button2" + Id} />
                        <label htmlFor={"button2" + Id}
                            className="textGradNeutro lineGradUnderLabel text-center bold textBlack txtsize-14 me-3"
                        >{t("HourFilterForDay.treehours")}</label>
                    </div>

                </div>
            </div>


            {/* 
            <div className="">
                <button className="borderNone" onClick={() => callbackTimeSelected(Object.values(data)[0])}>1 Ora</ button>
                <button onClick={() => callbackTimeSelected(Object.values(data)[1])}>3 Ore</ button>
            </div>  */}
        </>
    );
}






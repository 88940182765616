import React, { useState } from 'react';

import { TabTargetEcommerce } from '../../../cardComponents/cardtabtarget/TabTargetEcommerce'
import { SummaryCardEcommerce } from '../pages/ecommerceCards/SummaryCardEcommerce'
import { BarChartCard } from '../pages/commonCards/BarChartCard'
import { LineChartCard } from '../pages/commonCards/LineChartCard'
import IconPurpleTrendDaily from '../../../../img/IconPurpleTrendDaily.png'
import IconPurpleTrendHour from '../../../../img/IconPurpleTrendHour.png'
import IconPurpleClock from '../../../../img/IconPurpleClock.png'
import { useTranslation } from 'react-i18next';


export function Ecommerce({ ecommerceData, dateSelected }) {
  const { t, i18n } = useTranslation();


  return (
    <div>
      <div className="row p-3 ">
        <div className="ms-3 col-12">
          <h2 className="textBlack txtsize-18 regular">
            <span className="textBlack txtsize-21 bold">{t("Ecommerce.nb")}</span> {t("Ecommerce.note")}
          </h2>
        </div>
      </div>

      <div className="row p-3">
        <div className=" col-md-6 col-xl-8">
          <SummaryCardEcommerce
            SummaryCardEcommerceData={ecommerceData.summaryCardEcommerce}
          />

          <LineChartCard
          Title={"LineChartCard.title"}
            Id="EcommerceLine"
            PrimaryColor={"#DB91FF"}
            SecondaryColor={"#B558FD"}
            Icon={IconPurpleTrendDaily}
            IconTime={IconPurpleClock}
            data={ecommerceData.lineChartCard.data} />

          <BarChartCard
          TitleDay="BarChartCard.titleday"
          TitleWeek="BarChartCard.titleweek"
          TitleMonth="BarChartCard.titlemonth"
            Id="EcommerceBar"
            PrimaryColor={"#DB91FF"}
            SecondaryColor={"#B558FD"}
            Icon={IconPurpleTrendHour}
            data={ecommerceData.barChartCard.data}
          />
        </div>

        <div className="col-md-6 col-xl-4 bgGreyTrasparent borderRadius">
          <TabTargetEcommerce
          dateSelected={dateSelected}
            TabTargetEcommerceData={ecommerceData.tabTargetEcommerce}
          />
        </div>


      </div>
    </div>


  );
}






import React, { useState } from 'react';

import { TabTargetAttendance } from '../../../cardComponents/cardtabtarget/TabTargetAttendance'
import { SummaryCardAttendance } from '../pages/attendanceCards/SummaryCardAttendance'
import { BarChartCard } from '../pages/commonCards/BarChartCard'
import { LineChartCard } from '../pages/commonCards/LineChartCard'
import IconAzureTrendDaily from '../../../../img/IconAzureTrendDaily.png'
import IconAzureTrendHour from '../../../../img/IconAzureTrendHour.png'
import IconAzureClock from '../../../../img/IconAzureClock.png'

export function Attendance({ attendanceData, dateSelected }) {

  return (
    <div>
      <div className="row p-3">
        <div className=" col-md-6 col-xl-8">
          <SummaryCardAttendance
            summaryCardAttendanceData={attendanceData.summaryCardAttendance}
          /> 
          
          <LineChartCard
             Title={"LineChartCard.titleattendance"}
            Id="AttendanceLine"
            PrimaryColor={"#8db9ff"}
            SecondaryColor={"#7789ff"}
            Icon={IconAzureTrendDaily}
            IconTime={IconAzureClock}
            data={attendanceData.lineChartCard.data} />
          <BarChartCard
           TitleDay="BarChartCard.titledayaffluence"
           TitleWeek="BarChartCard.titleweekaffluence"
           TitleMonth="BarChartCard.titlemonthaffluence"
            Id="AttendanceBar"
            PrimaryColor={"#8db9ff"}
            SecondaryColor={"#7789ff"}
            Icon={IconAzureTrendHour}
            data={attendanceData.barChartCard.data}
          />
        </div>

        <div className="col-md-6 col-xl-4 bgGreyTrasparent borderRadius">
          <TabTargetAttendance
          dateSelected={dateSelected}
            tabTargetAttendanceData={attendanceData.tabTargetAattendance} />
        </div>


      </div>
    </div>


  );
}






import React, { useState, useEffect, useCallback, useRef } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, VirtualScroll, Sort } from '@syncfusion/ej2-react-grids';
import { SelectHoursButtons } from "../../../dashboard/tabContainer/pages/commonCards/commons/SelectHoursButtons"
//IMAGE AND ICON
import IconClockGreen from "../../../../img/IconClockGreen.png"
import IconClockRed from "../../../../img/IconClockRed.png"
import IconClockGrey from "../../../../img/IconClockGrey.png"
import IconArrowRed from "../../../../img/IconArrowRed.png"
import IconArrowGreen from "../../../../img/IconArrowGreen.png"
import "./cardtargetstatlist.css"
import { useTranslation } from 'react-i18next';

export function CardTargetStatList({ dateType, IconStat, Currency, Id, headerTextFirst, headerTextSecond, data, PrimaryColor, SecondaryColor }) {
    const { t, i18n } = useTranslation();
    const [statListData, setStatListData] = useState(Object.values(data)[0])
    const [numberAfterComma, setnumberAfterComma] = useState(null)



    useEffect(() => {
        if (Currency == true) {
            setnumberAfterComma(2)
        } else {
            setnumberAfterComma(null)
        }

    }, [data])

    const callbackSelectTime = useCallback((dataTimeSelected) => {
        setStatListData(dataTimeSelected)
    });


    useEffect(() => {
        setStatListData(Object.values(data)[0])

    }, [data])




    return (
        <div id="cardTargetStatList" className="borderRadius bgWhite p-3 mb-3 mx-2 ">
            <div className="d-flex  align-items-center mb-3">
                <img className=" img-icon20" src={IconStat} />
                <h3 className="textBlack txtsize-21 bold mb-0 m-1" >{t("CardTargetStatList.mystats")}</h3>
            </div>

            {Object.values(data).length > 1 ? (
                <SelectHoursButtons
                    PrimaryColor={PrimaryColor}
                    SecondaryColor={SecondaryColor}
                    Id={Id + "StatList"}
                    data={data}
                    callbackTimeSelected={callbackSelectTime}
                />
            ) : null}
            <div className="d-flex flex-row"><h3 className="textGrey txtsize-14 bold mb-0 m-1 d-flex ">{headerTextFirst}</h3><h3 className=" d-flex flex1 justify-content-center textGrey txtsize-14 bold mb-0 m-1">{headerTextSecond}</h3></div>

            <div className="mb-3">
                {statListData.map((item, i) =>
                    <div className="d-flex">
                        <div className="mt-2">
                            <img src={item.trend == "up" ? IconClockGreen : item.trend == "down" ? IconClockRed : IconClockGrey}
                                className="img-icon20" />
                        </div>
                        <div className="d-flex flex1 borderBottomGrey">
                            <div className="d-flex flex1">
                                <h3 className="m-2 mb-0 txtsize-12 regular">{item.time}</h3>
                            </div>
                            <div className="d-flex align-items-baseline flex1">
                                <span className="m-2 mb-0 bold  txtsize-14">{item.stats.toLocaleString(undefined, { minimumFractionDigits: numberAfterComma })}{Currency ? " €" : null}</span>
                                <img src={item.trend == "up" ? IconArrowGreen : item.trend == "down" ? IconArrowRed : null} className={item.trend == "up" || item.trend == "down" ? "img-icon12" : null} /></div>
                        </div>


                    </div>

                )
                }

            </div >
        </div>

    );
}


import { TabComponent } from '@syncfusion/ej2-react-navigations';
import React, { useState, useEffect } from 'react';
import { CardTargetMainChart } from './cardstarget/CardTargetMainChart'
import { CardTargetStatList } from './cardstarget/CardTargetStatList'
import './tabtargetstore.css'
import IconGreenMyStats from '../../../img/IconGreenMyStats.png'
import IconGreenFlag from '../../../img/IconGreenFlag.png'
import IconGreenTicket from '../../../img/IconGreenTicket.png'
import { useTranslation } from 'react-i18next';

export function TabTargetStore({dateType, tabTargetStoreData, dateSelected }) {
  const { t, i18n } = useTranslation();


  useEffect(() => {
    document.getElementById("storeHeaderTabButtonReveneu").innerText =`${t("tabTargetStore.reveneu")}`
    document.getElementById("storeHeaderTabButtonTicket").innerText =`${t("tabTargetStore.ticket")}`
    document.getElementById("storeHeaderTabButtonTicketAverage").innerHTML = `${t("tabTargetStore.ticketaverage")}`
  }, [i18n.language])

  
  return (
    <TabComponent id='tabTargetStore'>
      <div className="e-tab-header px-3 mt-3 w-auto">

        <div>
          <div className="storeHeaderTabButton mb-3 ">
            <span className="m-1 mb-0 reveneuTI"></span>
            <h1 className="txtsize-12 regular mb-1 text-center mt-0" id="storeHeaderTabButtonReveneu"></h1>
          </div>
        </div>


        <div>
          <div className="storeHeaderTabButton mb-3 ">
            <span className="m-1  mb-0 transactionTI"></span>
            <h1 className="txtsize-12 regular mb-1 text-center mt-0" id="storeHeaderTabButtonTicket"></h1>
          </div>
        </div>

        <div>
          <div className="storeHeaderTabButton mb-3 ">
            <span className="m-1 mb-0 transactionAverageTI"></span>
            <h1 className="txtsize-12 regular mb-1 text-center mt-0" id="storeHeaderTabButtonTicketAverage"></h1>
          </div>
        </div>


      </div>
      <div className="e-content borderRadius  px-2">
        {/* REVENEU Store */}
        <div>


          <CardTargetMainChart
          dateSelected={dateSelected}
            id="reveneuStore"
            Icon={null}
            IconFlag={IconGreenFlag}
            PrimaryColor={"#53E3BA"}
            SecondaryColor={"#2FCD91"}
            Title={tabTargetStoreData.cardTargetRevenueStore.nametarget}
            Description={t("CardTargetMainChartProgress.descriptionreveneustore")}
            state={tabTargetStoreData.cardTargetRevenueStore.state}
            min={tabTargetStoreData.cardTargetRevenueStore.min}
            max={tabTargetStoreData.cardTargetRevenueStore.max}
            value={tabTargetStoreData.cardTargetRevenueStore.value} />

          <CardTargetStatList
             dateType={dateType}
            Id="reveneuStore"
            IconStat={IconGreenMyStats}
            Currency={true}
            PrimaryColor={"#53E3BA"}
            SecondaryColor={"#2FCD91"}
            headerTextFirst={t("CardTargetStatList.time")}
            headerTextSecond={t("CardTargetStatList.reveneustore")}
            data={tabTargetStoreData.cardTargetStatListReveneu.data}
          />
        </div>

        {/* TICKET STORE */}
        <div>
          <CardTargetMainChart
           dateSelected={dateSelected}
            id="ticketStore"
            Icon={IconGreenTicket}
            IconFlag={IconGreenFlag}
            PrimaryColor={"#53E3BA"}
            SecondaryColor={"#2FCD91"}
            Title={tabTargetStoreData.cardTargetTicketStore.nametarget}
            Description={t("CardTargetMainChartProgress.descriptionticketstore")}
            state={tabTargetStoreData.cardTargetTicketStore.state}
            min={tabTargetStoreData.cardTargetTicketStore.min}
            max={tabTargetStoreData.cardTargetTicketStore.max}
            value={tabTargetStoreData.cardTargetTicketStore.value} />

          <CardTargetStatList
            Id="ticketStore"
            dateType={dateType}
            IconStat={IconGreenMyStats}
            Currency={false}
            PrimaryColor={"#53E3BA"}
            SecondaryColor={"#2FCD91"}
            headerTextFirst={t("CardTargetStatList.time")}
            headerTextSecond={t("CardTargetStatList.ticketstore")}
            data={tabTargetStoreData.cardTargetStatListTicket.data} />

        </div>

        {/* TICKET AVERAGE  */}
        <div>
          <CardTargetMainChart
           dateSelected={dateSelected}
            id="ticketAverageStore"
            Icon={null}
            IconFlag={IconGreenFlag}
            PrimaryColor={"#53E3BA"}
            SecondaryColor={"#2FCD91"}
            Description={t("CardTargetMainChartProgress.descriptionticketaveragestore")}
            Title={tabTargetStoreData.cardTargetTicketAverageStore.nametarget}
            state={tabTargetStoreData.cardTargetTicketAverageStore.state}
            min={tabTargetStoreData.cardTargetTicketAverageStore.min}
            max={tabTargetStoreData.cardTargetTicketAverageStore.max}
            value={tabTargetStoreData.cardTargetTicketAverageStore.value} />
          <CardTargetStatList
             dateType={dateType}
            Id="ticketAverageStore"
            IconStat={IconGreenMyStats}
            Currency={true}
            PrimaryColor={"#53E3BA"}
            SecondaryColor={"#2FCD91"}
            headerTextFirst={t("CardTargetStatList.time")}
            headerTextSecond={t("CardTargetStatList.ticketaveragestore")}
            data={tabTargetStoreData.cardTargetStatListTicketAverage.data} />

        </div>
        <div>
          <div>
          </div>
        </div>
      </div>
    </TabComponent>
  );
}

import React, { useState } from 'react';
import "./cardtotalturnover.css"
import { PieChart } from "../../../../cardComponents/common/charts/PieChart"
import IconTotalMoney from "../../../../../img/IconTotalMoney.png"
import IconStoreGreen from "../../../../../img/IconStoreGreen.png"
import IconEcommercePurple from "../../../../../img/IconEcommercePurple.png"
import { useTranslation } from 'react-i18next';

export function CardTotalTurnover({CardTotalTurnoverData}) {
	const { t, i18n } = useTranslation();
    return (
        <div id="cardTotalTurnover" className="p-4 bgWhite shadowDrop borderRadius h-100">
            <div className=" d-flex flex-wrap   ">
                <div className="col-md-6">
                    <div className="d-flex align-items-end">
                        <img className="iconTotalMoney me-2" src={IconTotalMoney} />
                        <h3 className="textBlack bold mb-0 txtsize-21">{t("CardTotalTransaction.title")} </h3>
                    </div>
                    <h2 className="textGrey bold my-4 txtsize-32">{CardTotalTurnoverData.total.toLocaleString(undefined, {minimumFractionDigits: 2})} €</h2>
                    <div className="d-flex align-items-center mb-4" >
                        <img className="iconStoreGreen me-2" src={IconStoreGreen} />
                        <div className="d-flex flex-column" >
                            <h4 className="textGreen semibold txtsize-18 mb-0">{t("CardTotalTransaction.store")}</h4>
                            <p className="textBlack regular txtsize-18 mb-0">{CardTotalTurnoverData.store.toLocaleString(undefined, {minimumFractionDigits: 2})} €</p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-1" >
                        <img className="iconEcommercePurple me-2" src={IconEcommercePurple} />
                        <div className="d-flex flex-column" >
                            <h4 className="textPurple semibold txtsize-18 mb-0">{t("CardTotalTransaction.ecommerce")}</h4>
                            <p className="textBlack regular txtsize-18 mb-0">{CardTotalTurnoverData.online.toLocaleString(undefined, {minimumFractionDigits: 2})} €</p>
                        </div>
                    </div>
                    
                </div>
                <div className="pieChartContent">
                    <PieChart 
                    Id="TotalTurnoverPie"
                    PiechartData={CardTotalTurnoverData.pieChart} />
                </div>
            </div>
        </div>
    );
}
import React, { useState } from 'react';
import IconPurpleTransactionAverage from "../../../../../img/IconPurpleTransactionAverage.png"
import IconPurpleTransaction from "../../../../../img/IconPurpleTransaction.png"
import IconPurpleMoney from "../../../../../img/IconPurpleMoney.png"
import { useTranslation } from 'react-i18next';
import "./summarycardecommerce.css"


export function SummaryCardEcommerce({SummaryCardEcommerceData}) {
    const { t, i18n } = useTranslation();

    return (
        <div id="summaryCardEcommerce" className="shadowDrop borderRadius bgWhite">
            <div className="p-4  row  justify-content-center ">
                <div className="col-sm-4 justify-content-center align-self-center">
                    <div className="d-flex flex-wrap justify-content-around 
                     align-self-center align-content-center
                     borderRight
                     align-items-center p-1">

                        <div>
                            <h3 className="textPurple txtsize-16 bold mb-0" > {t("SummaryCardEcommerce.revenue")}</h3>
                            <p className="textBlack txtsize-24 bold mb-0">{SummaryCardEcommerceData.revenue.toLocaleString(undefined, {minimumFractionDigits: 2})} €</p>
                        </div>
                        <img className=" m-2 img-icon60" src={IconPurpleMoney} />
                    </div>
                </div>

                <div className="col-sm-4 justify-content-around align-self-center">
                <div className="d-flex flex-wrap justify-content-around  align-content-center
                borderRight
                align-self-center align-items-center p-1">

                        <div>
                            <h3 className="textPurple txtsize-16 bold mb-0" >{t("SummaryCardEcommerce.transaction")}</h3>
                            <p className="textBlack txtsize-24 bold mb-0">{SummaryCardEcommerceData.transaction.toLocaleString()}</p>
                        </div>
                        <img className=" m-2 img-icon60" src={IconPurpleTransaction} />
                    </div>

                </div>

                <div className="col-sm-4 justify-content-around align-self-center">
                <div className="d-flex flex-wrap justify-content-around  align-self-center  align-content-center align-items-center p-1">

                        <div>
                            <h3 className="textPurple txtsize-16 bold mb-0" >{t("SummaryCardEcommerce.transactionaverage")}</h3>
                            <p className="textBlack txtsize-24 bold mb-0">{SummaryCardEcommerceData.transactionaverage.toLocaleString(undefined, {minimumFractionDigits: 2})} €</p>

                        </div>
                        <img className=" m-2 img-icon60" src={IconPurpleTransactionAverage} />
                    </div>

                </div>

            </div>
        </div>
    );
}
import React, { useState, useEffect, useCallback } from 'react';
import { BarChart } from "./commons/BarChart"
import { SelectHoursButtons } from "./commons/SelectHoursButtons"

import { useTranslation } from 'react-i18next';

export function BarChartCard({TitleDay, TitleWeek, TitleMonth, dateType, PrimaryColor, SecondaryColor, Id, Icon, data }) {
    const { t, i18n } = useTranslation();


    const [barData, setBarData] = useState(Object.values(data)[0])

    useEffect(() => {
        setBarData(Object.values(data)[0])
    }, [data])

    const callbackSelectTime = useCallback((timeSelected) => {
        setBarData(timeSelected)
    });


    return (
        <div id={'BarChartCard' + Id} className='p-3 my-2 borderRadius bgWhite shadowDrop'>
            <div className='d-flex flex-row m-1 align-items-center'>
                <img src={Icon} className='img-icon30 m-2' />
                <h3 className='textBlack bold txtsize-24 mb-0'>
               { dateType=="Day"?`${t(TitleDay)}`: dateType=="Week"? `${t(TitleWeek)}`:`${t(TitleMonth)}`}
               </h3>
            </div>
            {Object.values(data).length > 1 ? (
                <SelectHoursButtons
                    PrimaryColor={PrimaryColor}
                    SecondaryColor={SecondaryColor}
                    Id={Id}
                    data={data}
                    callbackTimeSelected={callbackSelectTime}
                />
            ) : null}
            <BarChart
                PrimaryColor={PrimaryColor}
                SecondaryColor={SecondaryColor}
                Id={Id}
                data={barData}
            />

        </div>);
}






import React, { useState } from 'react';
import "./footers.css"

//IMAGE AND ICON
import LogoWhiteImok from "../../../img/LogoWhiteImok.png"
import LogoAquaImok from "../../../img/LogoAquaImok.png"
import IconVioletFacebook from "../../../img/IconVioletFacebook.png"
import IconVioletTwitter from "../../../img/IconVioletTwitter.png"
import IconVioletInstagram from "../../../img/IconVioletInstagram.png"
import IconVioletLinkedin from "../../../img/IconVioletLinkedin.png"
import { useTranslation } from 'react-i18next';
import {LanguageSelector} from "../LanguageSelector"
export function FooterPoweredBy() {


    return (
        <div id="footerPoweredBy" className="">
            <div className="d-flex justify-content-end p-0">
                <img className="img-icon80 m-2" src={LogoWhiteImok} />
            </div>
        </div>
    );
}


export function FooterSocialAndFaq() {
    const { t, i18n } = useTranslation();
    return (
        <div id="FooterSocialAndFaq" className="bgWhite d-flex ">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-auto col-xs-12 d-flex flex-column justify-content-center px-5 py-2">
                    <div className="NavbarLangageselectorContainer">
                        <LanguageSelector />
                    </div>
                        <p className="textViolet regular my-1 mx-2 text-size12 align-self-center align-self-md-start ">{t("login.followon")}</p>
                        <div className=" d-flex justify-content-start align-self-center p-0">
                            <img className="img-icon20 my-0 mx-2 iconsocial pointer" src={IconVioletFacebook} />
                            <img className="img-icon20 my-0 mx-2 iconsocial pointer" src={IconVioletTwitter} />
                            <img className="img-icon20 my-0 mx-2 iconsocial pointer" src={IconVioletInstagram} />
                            <img className="img-icon20 my-0 mx-2 iconsocial pointer"  src={IconVioletLinkedin} />
                        </div>
                    </div>

                    <div className="col-md col-xs-12 d-flex flex-wrap  justify-content-center align-self-center p-2">
                           <a className="textCeleste regular my-1 mx-2 pointer">{t("login.price")}</a>
                           <a className="textCeleste regular my-1 mx-2 pointer">{t("login.imokgroup")}</a>
                           <a className="textCeleste regular my-1 mx-2 pointer">{t("login.legal")}</a>
                           <a className="textCeleste regular my-1 mx-2 pointer">{t("login.contact")}</a>
                    </div>
                    <div className="col-md-auto col-xs-12  d-flex justify-content-center align-self-center px-5 py-2">
                        <img className="img-icon80 m-2" src={LogoAquaImok} />
                    </div>
                </div>
            </div>
        </div>
    );
}






import React, { useState } from 'react';

import IconGreenReveneu from "../../../../../img/IconGreenReveneu.png"
import IconGreenTicket from "../../../../../img/IconGreenTicket.png"
import IconGreenTicketAverage from "../../../../../img/IconGreenTicketAverage.png"
import { useTranslation } from 'react-i18next';
import "./summarycardstore.css"


export function SummaryCardStore({summaryCardStoreData}) {
    const { t, i18n } = useTranslation();

    return (
        <div id="summaryCardStore" className="shadowDrop borderRadius bgWhite">
            <div className="p-4  row  justify-content-center ">
                <div className="col-sm-4 justify-content-center align-self-center">
                    <div className="d-flex flex-wrap justify-content-around 
                     align-self-center align-content-center
                     borderRight
                     align-items-center p-1">

                        <div>
                            <h3 className="textGreen txtsize-16 bold mb-0" >{t("SummaryCardStore.reveneu")}</h3>
                            <p className="textBlack txtsize-24 bold mb-0">{summaryCardStoreData.reveneu.toLocaleString(undefined, {minimumFractionDigits: 2})} €</p>
                        </div>
                        <img className=" m-2 img-icon60" src={IconGreenReveneu} />
                    </div>
                </div>

                <div className="col-sm-4 justify-content-around align-self-center">
                <div className="d-flex flex-wrap justify-content-around  align-content-center
                borderRight
                align-self-center align-items-center p-1">

                        <div>
                            <h3 className="textGreen txtsize-16 bold mb-0" >{t("SummaryCardStore.transaction")}</h3>
                            <p className="textBlack txtsize-24 bold mb-0">{summaryCardStoreData.transaction.toLocaleString()}</p>
                        </div>
                        <img className=" m-2 img-icon60" src={IconGreenTicket} />
                    </div>

                </div>

                <div className="col-sm-4 justify-content-around align-self-center">
                <div className="d-flex flex-wrap justify-content-around  align-self-center  align-content-center align-items-center p-1">

                        <div>
                            <h3 className="textGreen txtsize-16 bold mb-0" >{t("SummaryCardStore.transactionaverage")}</h3>
                            <p className="textBlack txtsize-24 bold mb-0">{summaryCardStoreData.transactionaverage.toLocaleString(undefined, {minimumFractionDigits: 2})} €</p>

                        </div>
                        <img className=" m-2 img-icon60" src={IconGreenTicketAverage} />
                    </div>

                </div>

            </div>
        </div>
    );
}
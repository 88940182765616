import React, { useState,useEffect } from 'react';
import LogoWhiteSaga from "../../../../img/LogoWhiteSaga.png"
import { useTranslation } from 'react-i18next';

export function CardTargetMainChartSuccess({dateSelected, IconFlag, Icon, Title, PrimaryColor, SecondaryColor,max, value }) {
    const { t, i18n } = useTranslation();

    const [numberAfterComma, setNumberAfterComma] = useState(undefined)

    useEffect(() => {
        if (Icon ) {
            setNumberAfterComma(undefined)
        } else {
            setNumberAfterComma(2)
        }

    }, [])



    return (
        <div id="cardTargetMainChartSuccess" className="borderRadius bgWhite  mx-2 my-3 minHeightCard flex-column d-flex  ">
            <div className="d-flex flex-column p-4 	flex1 justify-content-between">
                <div className="d-flex  align-items-center my-2">
                    <img className=" m-2 img-icon20" src={IconFlag} />
                    <h3 className="textBlack txtsize-18 bold mb-0" >{t("CardTargetMainChartSuccess.mytarget")}</h3>
                </div>
                <div className="mx-3 my-2">
                <h3  style={{ color: PrimaryColor }} className="txtsize-18 bold mb-0 text-uppercase" >{t("CardTargetMainChartSuccess.nametarget")}</h3>
                <h3 className="textBlack  txtsize-18 bold mb-0" >{Title}</h3>
                </div>
                <div className="d-flex  align-items-center justify-content-center my-2 mx-3">
                    <div className="d-flex  align-items-center flex-wrap">
                        <p style={{ color: PrimaryColor }} className={" txtsize-18  bold text-uppercase me-2 m-0"}>{t("CardTargetMainChartSuccess.start")} </p>
                        <p className="textBlack txtsize-18 regular me-2 m-0 ">{dateSelected.startDate.toLocaleString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })}</p>
                    </div>
                    <div className="d-flex  align-items-center flex-wrap">
                        <p style={{ color: PrimaryColor }} className={" txtsize-18  bold text-uppercase me-2 m-0"}>{t("CardTargetMainChartSuccess.end")} </p>
                        <p className="textBlack txtsize-18 regular me-2 m-0">{dateSelected.endDate.toLocaleString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })}</p>
                    </div> </div>
                <div className="d-flex  align-items-center justify-content-center m-2">
                    <p style={{ color: PrimaryColor }} className={" txtsize-32 bold text-uppercase ms-2 m-2"} >
                    {value.toLocaleString(undefined, { minimumFractionDigits: numberAfterComma })} {Icon ? <img className="img-icon20 align-middle" src={Icon} /> : "€"}<span className="textBlack txtsize-21 regular m-2 me-0" > / {max.toLocaleString(undefined, { minimumFractionDigits: numberAfterComma })}</span></p>
                </div>

               <div className="borderRadius"
                style={{background:`linear-gradient(215deg, ${PrimaryColor},${SecondaryColor})`}}
                >
                    <div className="sectionTargetSuccess d-flex flex-row align-items-center p-4 " >

                    <img src={LogoWhiteSaga} className="img-icon40" />
                    <div className="d-flex flex-column m-2">
                        <p className=" txtsize-32 bold  m-0 textWhite d-flex" >{t("CardTargetMainChartSuccess.successtitle")}</p>
                        <p className="textWhite txtsize-18 regular  d-flex m-0" >{t("CardTargetMainChartSuccess.successsubtitle")}</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}


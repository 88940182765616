import React, { useState } from 'react';
import { LoginContent } from '../components/autentication/Login'
import { FooterSocialAndFaq } from '../components/commons/bars/Footers'
import { NavbarOnlyLogoSaga } from '../components/commons/bars/Navbars'
import './login.css'

export function Login() {
  // Declare a new state variable, which we'll call "count"
  const [count, setCount] = useState(0);

  return (
    <div id="loginPage" className="d-flex flex-column align-items-stretch">
      <NavbarOnlyLogoSaga />
      <div className="container-fluid container-xl h-100 flex-grow-1 mt-n5">
        <div className="row justify-content-center mt-n6">
          <div className="col-md-7 col-xl-5">
            <LoginContent />
          </div>
        </div>
      </div>
      <FooterSocialAndFaq />
    </div>
  );
}
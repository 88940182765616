import { Category, ChartComponent, ColumnSeries, Inject, DataLabel, SeriesCollectionDirective, SeriesDirective, ScrollBar, Zoom } from '@syncfusion/ej2-react-charts';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function BarChart({ PrimaryColor, SecondaryColor, Id, data }) {
    const { t, i18n } = useTranslation();


    // const [scroll, setScroll] = useState(data.length > 12)
    // const [scrollValue, setScrollValue] = useState(1)
    // const [loaded, setLoaded] = useState(false)
    // useEffect(() => { setScroll(data.length > 12) }, [data])
    // useEffect(() => { ScrollValue() }, [scroll])

    // useEffect(() => {
    //     if (!loaded) {
    //         setLoaded(true)

    //         setTimeout(resizePez, 3000);
    //     }
    // }, [])



    // function ScrollValue() {
    //     if (scroll) {
    //         setScrollValue(0.5)

    //     } else {
    //         setScrollValue(1)
    //     }
    // }


    // function resizePez() {
    //     document.getElementById('charts' + Id).width  = document.getElementById('charts' + Id).width + 1
    // }


    return (
        // !loaded ? null :
            <>
                <ChartComponent
                    id={'charts' + Id}
                    primaryXAxis={{
                        valueType: 'Category',
                        interval: 1,
                        majorGridLines: { width: 0 },
                        // zoomFactor: scrollValue
                    }}
                    zoomSettings={{
                        enableMouseWheelZooming: false,
                        enablePinchZooming: false,
                        enableSelectionZooming: false,
                        enableDeferredZooming: false,
                        mode: 'X',
                        enableScrollbar: true,
                    }}
                    chartArea={{ border: { width: 0 } }}

                    onZooming={(args) => console.log(args)}
                // loaded={(args) => onChartLoad(args)}
                >
                    <Inject services={[ColumnSeries, DataLabel, Category, Zoom, ScrollBar]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective
                            fill={`url(#gradientes${Id})`}
                            cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }}
                            width="100%"
                            dataSource={data} xName='time' type='Column'
                            yName='sales' name='Sales'
                            marker={{
                                dataLabel: {
                                    visible: true,
                                    // position: 'Top',
                                    font: {
                                        fontSize: '16px',
                                        fontWeight: '700', color: SecondaryColor
                                    }
                                }
                            }}
                        />
                    </SeriesCollectionDirective>
                </ChartComponent>
                <svg width="0" height="0">
                    <defs>
                        <linearGradient id={"gradientes" + Id} gradientTransform="rotate(90)">
                            <stop offset="0" stopColor={PrimaryColor}></stop>
                            <stop offset="100%" stopColor={SecondaryColor} ></stop>
                        </linearGradient>
                    </defs>
                </svg>

            </>

    );
}






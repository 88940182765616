import { TabComponent } from '@syncfusion/ej2-react-navigations';
import React, { useState, useEffect } from 'react';
import { Store } from './pages/Store'
import { Overview } from './pages/Overview'
import { Ecommerce } from './pages/Ecommerce'
import { Attendance } from './pages/Attendance'
import { useTranslation } from 'react-i18next';
import './tabcontainer.css';

export function TabContainer({ dashboardData, dateSelected,dateType}) {
  const { t, i18n } = useTranslation();

  // const [datiTab, setDatiTab] = useState(dashboardData.tabButton.store.toLocaleString())
  // const [loading, setLoading] = useState("ciao")
  useEffect(() => {
    document.getElementById("mainTabStore").innerText = dashboardData.tabButton.store.toLocaleString(undefined, {minimumFractionDigits: 2}) + " €"
    document.getElementById("mainTabAttendances").innerHTML = `${dashboardData.tabButton.presence.toLocaleString() } <span class="iconPeople"></span>`
    document.getElementById("mainTabOnline").innerText = dashboardData.tabButton.online.toLocaleString(undefined, {minimumFractionDigits: 2}) + " €"
  }, [dateSelected])

  useEffect(() => {

    document.getElementById("mainTabOverviewName").innerText =`${t("TabButtons.overview")}`
    document.getElementById("mainTabStoreName").innerText =`${t("TabButtons.storeReceipts")}`
    document.getElementById("mainTabAttendancesName").innerHTML = `${t("TabButtons.attendances")}`
    document.getElementById("mainTabOnlineName").innerText = `${t("TabButtons.ecommerce")}`
  }, [i18n.language])

  return (
    <TabComponent id='mainTab' dragArea={false} allowDragAndDrop={false}>
      <div className="e-tab-header">
        <div>
          <div className="overview headerTabButton mb-3"><h1  id="mainTabOverviewName"className="txtsize-16 bold mb-1"></h1>
            <span className="m-2"></span>
          </div>
        </div>
        <div>
          <div className="recessed headerTabButton mb-3">
            <div className=" d-flex flex-column">
              <h1 id="mainTabStoreName" className="txtsize-16 bold  mb-1">{t("TabButtons.storeReceipts")}</h1>
              <p id="mainTabStore" className="txtsize-18 bold m-0 textBlack"></p>
            </div>
            <span className="m-2"></span>
          </div>
        </div>

        <div>
          <div className="sensory headerTabButton mb-3">
            <div className=" d-flex flex-column">
              <h1 id="mainTabAttendancesName" className="txtsize-16 bold  mb-1">{t("TabButtons.attendances")}</h1>
              <p id="mainTabAttendances" className="txtsize-18 bold m-0 textBlack"></p>
            </div>
            <span className="m-2"></span>
          </div>
        </div>

        <div>
          <div className="ecommerce headerTabButton mb-3">
            <div className=" d-flex flex-column">
              <h1 id="mainTabOnlineName" className="txtsize-16 bold  mb-1">{t("TabButtons.ecommerce")}</h1>
              <p id="mainTabOnline" className="txtsize-18 bold m-0 textBlack"></p>
            </div>
            <span className="m-2"></span>
          </div>
        </div>
      </div>
      <div className="e-content">
        <div>
          <Overview
            overviewData={dashboardData.overview}
            dateSelected={dateSelected} /> 
        </div>
        <div>
            <Store
            dateType={dateType}
            storeData={dashboardData.store}
            dateSelected={dateSelected} />  
        </div>
        <div>
          <Attendance
          dateType={dateType}
            attendanceData={dashboardData.attendance}
            dateSelected={dateSelected} /> 
        </div>
        <div>
          <Ecommerce
          dateType={dateType}
            ecommerceData={dashboardData.ecommerce}
            dateSelected={dateSelected} />  
        </div>

      </div>
    </TabComponent>
  );
}

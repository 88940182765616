import React, { useState, useEffect } from 'react';


export function CardTargetDetails({ Icon, Currency, Title, Description, PrimaryColor }) {

    const [numberAfterComma, setNumberAfterComma] = useState(undefined)


    useEffect(() => {
        if (Currency == true) {
            setNumberAfterComma(2)
        } else {
            setNumberAfterComma(undefined)
        }

    }, [])


    return (
        <div id="cardTargetDetails" className="borderRadius bgWhite  mx-2 my-3 ">
            <div className="d-flex  align-items-center p-4">
                <img className=" m-2 img-icon50" src={Icon} />
                <div>
                    <h3 style={{ color: PrimaryColor }} className=" txtsize-18 bold mb-0" >{Title}</h3>
                    <p className="textBlack txtsize-24 bold mb-0">{Description.toLocaleString(undefined, { minimumFractionDigits: numberAfterComma })}{Currency ? " €" :null }</p>
                </div>
            </div>
        </div>
    );
}


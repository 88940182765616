import i18next from "i18next";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import flagEng from "../../img/eng.png";
import flagFr from "../../img/fr.png";
import flagIta from "../../img/ita.png";
import "./language-selector.css";

export const LanguageSelector = () => {
   const [imageActive, setImageActive] = useState("");
  const [langActive, setLangActive] = useState("");

  const { t, i18n } = useTranslation();

  const languages = [
    {name: "fr", flag: flagFr},
    {name: "it", flag: flagIta},
    // {name: "en", flag: flagEng},
  ];

  useEffect(()=>{
    if(i18next.language) {
        console.log(i18n.language)
        changeLang(i18n.language.split("-")[0])
    }
  }, [])

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    setImageActive(selectActiveFlag(lang));
    setLangActive(lang);
  }

  const handleClick = (lang) => {
   changeLang(lang);

    // const data = new FormData();
    // data.append("language", lang);
    // setLanguage(data);
  }

  const selectActiveFlag = (langActive) => {
    switch (langActive) {
      case "it":
        return flagIta;
      case "fr":
        return flagFr;
      //  case "en":
      //  return flagEng;
      default:
        return flagIta;
    }
  }

    return (   
        <div className="lang-list">
           {languages.map((language) => {
            return (
              langActive !== language.name ? 
                <button 
                  id={language.name}
                  key={language.name}
                  className="lang-item row m-0" 
                  onClick={()=>handleClick(language.name)} 
                >
                  <img alt="" className="col-12 py-2 px-3" src={language.flag} />
                </button>
              : null
            );
          })}
          {languages.map((language) => {
            return (
              langActive === language.name ? 
                <button 
                  id={language.name}
                  key={language.name}
                  className="lang-item active row m-0" 
                >
                  <img alt="" className="col-12 py-2 px-3" src={language.flag} />
                </button>
              : null
            );
          })} 
        </div>  
    );
}

import React, { useState, useCallback, useEffect } from 'react';
import { TabContainer } from '../components/dashboard/tabContainer/TabContainer'
import { NavbarStore } from '../components/commons/bars/Navbars'
import { SideBar } from '../components/commons/bars/Sidebars'
import { CardTip } from '../components/dashboard/tip/CardTip'
import { SelectDate } from '../components/dashboard/selectDate/CardSelectDate'
import { FooterPoweredBy } from '../components/commons/bars/Footers'
import { Prototype_it } from '../Prototype'
import { Prototype_fr } from '../Prototype_fr'
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import './dashboard.css'

export function Dashboard() {
  const { t, i18n } = useTranslation();

  const [dateType, setDateTypeSelected] = useState("Day");
  const [dateStart, setDataSelectedStart] = useState(new Date());
  const [dateEnd, setDataSelectedEnd] = useState(new Date());
  const [Prototype, setPrototype] = useState(Prototype_it);

  const [prototypefromDate, setPrototypeDate] = useState(Prototype.day);

  const callbackDateType = useCallback((dateType) => {
    setDateTypeSelected(dateType)
  });

  useEffect(() => {
    console.log(i18next.language)
    if (i18next.language == "it") {
      if (dateType == "Week") {
        setPrototypeDate(Prototype_it.week)
      }
      else if (dateType == "Day") {
        setPrototypeDate(Prototype_it.day)
      }
      else if (dateType == "Month") {
        setPrototypeDate(Prototype_it.month)
      }

    } else if (i18next.language == "fr") {
      if (dateType == "Week") {
        setPrototypeDate(Prototype_fr.week)
      }
      else if (dateType == "Day") {
        setPrototypeDate(Prototype_fr.day)
      }
      else if (dateType == "Month") {
        setPrototypeDate(Prototype_fr.month)
      }


    }

  }, [i18next.language])


  useEffect(() => {
    console.log(i18next.language)
    if (i18next.language == "it") {
      if (dateType == "Week") {
        setPrototypeDate(Prototype_it.week)
      }
      else if (dateType == "Day") {
        setPrototypeDate(Prototype_it.day)
      }
      else if (dateType == "Month") {
        setPrototypeDate(Prototype_it.month)
      }

    } else if (i18next.language == "fr") {
      if (dateType == "Week") {
        setPrototypeDate(Prototype_fr.week)
      }
      else if (dateType == "Day") {
        setPrototypeDate(Prototype_fr.day)
      }
      else if (dateType == "Month") {
        setPrototypeDate(Prototype_fr.month)
      }


    }

  }, [dateType])

  const callbackDateArr = useCallback((dateStart, dateEnd) => {
    setDataSelectedStart(dateStart)
    setDataSelectedEnd(dateEnd)
  });




  // useEffect(() => {
  //   if (dateType == "Week") {
  //     setPrototypeDate(Prototype.week)
  //   }
  //   else if (dateType == "Day") {
  //     setPrototypeDate(Prototype.day)
  //   }
  //   else if (dateType == "Month") {
  //     setPrototypeDate(Prototype.month)
  //   }

  // }, [dateType])



  return (
    <div className="dashboard">
      {console.log(Prototype)}
      <NavbarStore />

      <SideBar />
      <div id="dashboardBody" className="container-fluid container-xl ">
        <div className="row">
          <div className="p-3 col-md-6">

            <SelectDate
              parentCallbackDateArr={callbackDateArr}
              parentCallbackDateType={callbackDateType} />
          </div>
          <div className="p-3 col-md-6">
            <CardTip
              title={prototypefromDate.tip.title}
              description={prototypefromDate.tip.description} />
          </div>
        </div>
        <div className="row">
          <div className="p-3 col-12">
            {/* {dateType}{dateSelected.startDate.toLocaleString()} */}
            <TabContainer
              dateType={dateType}
              dateSelected={{ startDate: dateStart, endDate: dateEnd }}
              dashboardData={prototypefromDate} />
          </div>
        </div>
        <FooterPoweredBy />
      </div>

    </div>
  );
}
import React from 'react';
import "./cardselectdate.css"
import { DateInputGroup } from './datecomponent/DateInputGroup'

export function SelectDate({ parentCallbackDateArr, parentCallbackDateType }) {


  return (
    <>
      <DateInputGroup parentCallbackDateArr={parentCallbackDateArr} parentCallbackDateType={parentCallbackDateType} />
    </>
  );
}


import React, { useState } from 'react';
import "./cardtip.css"
//IMAGE AND ICON
import IconTip from "../../../img/IconTip.png"
import { useTranslation } from 'react-i18next';



export function CardTip({title, description}) {

    const { t, i18n } = useTranslation();
    return (
        <div id="cardTip" className="borderRadius shadowDrop h-100">
            <div className="innerContainercardTip p-3 d-flex ">
                <img className="iconTips img-icon100  mx-2 " src={IconTip} />
                <div>
                <h3 className="textBlack txtsize-21 bold" >{t( `cardTip.${title}` )}</h3>
                <p className="textGrey txtsize-14 regular">{t( `cardTip.${description}` )}{}</p>
                </div>
            </div>
        </div>
    );
}


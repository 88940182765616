export const Prototype_it = {
    "day": {
        "tip": {
            "title": "titledaily",
            "description": "descriptiondaily"
        },
        "tabButton": {
            "store": 8085.35,
            "presence": 758,
            "online": 7123.10,
        },
        "overview": {
            "totalTurnoverCard": {
                "total": 15208.45,
                "store": 8085.35,
                "online": 7123.10,
                "pieChart": [
                    { x: 'store', y: 53.16 },
                    { x: 'online', y: 46.84 }
                ]
            },
            "totalTransactionCard": {
                "total": "417",
                "store": "128",
                "online": "289",
                "pieChart": [
                    { x: 'store', y: 30.70 },
                    { x: 'online', y: 69.30 }
                ],
                "ticket": 35.47
            },
            "clientCard": {
                "storeClient": 10.67,
                "ticket": 128,
                "presence": 758,
                "percentageStore": 16.89,
                "onlineClient": 3.04,
                "transaction": 289,
                "unicClient": 2345,
                "percentageOnline": 12.32
            },
            "trafficComparision": {
                "data": [
                    { x: "09:00", y2: 0, y: 0 },
                    { x: "12:00", y2: 111, y: 315 },
                    { x: "15:00", y2: 350, y: 805 },
                    { x: "18:00", y2: 523, y: 1340 },
                    { x: "21:00", y2: 758, y: 2345 },

                ]

            },
            "targetCard": {
                "presenceTarget": "Aumenta presenze",
                "valuePresence": 758,
                "maxPresence": 1000,
                "onlineTarget": "Aumenta fatturato",
                "valueOnline": 7123.10,
                "maxOnline": 10000
            }
        },
        "store": {
            "summaryCardStore": {
                "reveneu": 8085.35,
                "transaction": 128,
                "transactionaverage": 63.17,
            },

            "lineChartCard": {
                "data": [

                    { time: '09:00', sales: 0 },
                    { time: '12:00', sales: 1427.68 },
                    { time: '15:00', sales: 2828.33 },
                    { time: '18:00', sales: 4374.45 },
                    { time: '21:00', sales: 8085.35 }



                ]
            },
            "barChartCard": {
                "data": {
                    "onehour": [

                        { time: '09:00', sales: 200.57 },
                        { time: '10:00', sales: 487.90 },
                        { time: '11:00', sales: 739.21 },
                        { time: '12:00', sales: 692.34 },
                        { time: '13:00', sales: 427.60 },
                        { time: '14:00', sales: 280.71 },
                        { time: '15:00', sales: 254.13 },
                        { time: '16:00', sales: 568.43 },
                        { time: '17:00', sales: 723.56 },
                        { time: '18:00', sales: 935.87 },
                        { time: '19:00', sales: 1738.93 },
                        { time: '20:00', sales: 1036.10 },
                        { time: '21:00', sales: 0 }


                    ],
                    "threehours": [

                        { time: '09:00', sales: 1427.68 },
                        { time: '12:00', sales: 1400.65 },
                        { time: '15:00', sales: 1546.12 },
                        { time: '18:00', sales: 3710.90 },
                        { time: '21:00', sales: 0.00 }

                    ]
                }
            },

            "tabTargetStore": {
                "cardTargetRevenueStore": {
                    "state": "startNew", //success startNew progress
                    "nametarget": "Aumenta fatturato",
                    "min": 0,
                    "max": 100,
                    "value": 40
                },
                "cardTargetTicketStore": {
                    "state": "progress", //success startNew progress
                    "nametarget": "Aumenta scontrini",
                    "min": 0,
                    "max": 200,
                    "value": 128
                },
                "cardTargetTicketAverageStore": {
                    "state": "success", //success startNew progress
                    "nametarget": "Aumenta scontrino medio",
                    "min": 0,
                    "max": 50.00,
                    "value": 63.17
                },
                "cardTargetStatListReveneu": {
                    "data": {
                        "onehour": [

                            { time: "09:00-10:00", stats: 200.57, trend: "down" },
                            { time: "10:00-11:00", stats: 487.90, trend: "" },
                            { time: "11:00-12:00", stats: 739.21, trend: "" },
                            { time: "12:00-13:00", stats: 692.34, trend: "" },
                            { time: "13:00-14:00", stats: 427.60, trend: "" },
                            { time: "14:00-15:00", stats: 280.71, trend: "" },
                            { time: "15:00-16:00", stats: 254.13, trend: "" },
                            { time: "16:00-17:00", stats: 568.43, trend: "" },
                            { time: "17:00-18:00", stats: 723.56, trend: "" },
                            { time: "18:00-19:00", stats: 935.87, trend: "" },
                            { time: "19:00-20:00", stats: 1738.93, trend: "up" },
                            { time: "20:00-21:00", stats: 1036.10, trend: "" }

                        ],
                        "threehours": [

                            { time: "09:00-12:00", stats: 1427.68, trend: "" },
                            { time: "12:00-15:00", stats: 1400.65, trend: "down" },
                            { time: "15:00-18:00", stats: 1546.12, trend: "" },
                            { time: "18:00-21:00", stats: 3710.90, trend: "up" }

                        ]
                    }
                },

                "cardTargetStatListTicket": {
                    "data": {
                        "onehour": [

                            { time: "09:00-10:00", stats: 3, trend: "down" },
                            { time: "10:00-11:00", stats: 6, trend: "" },
                            { time: "11:00-12:00", stats: 13, trend: "" },
                            { time: "12:00-13:00", stats: 11, trend: "" },
                            { time: "13:00-14:00", stats: 8, trend: "" },
                            { time: "14:00-15:00", stats: 5, trend: "" },
                            { time: "15:00-16:00", stats: 4, trend: "" },
                            { time: "16:00-17:00", stats: 7, trend: "" },
                            { time: "17:00-18:00", stats: 15, trend: "" },
                            { time: "18:00-19:00", stats: 17, trend: "" },
                            { time: "19:00-20:00", stats: 23, trend: "up" },
                            { time: "20:00-21:00", stats: 16, trend: "" },
                            { time: "21:00-22:00", stats: 0, trend: "" }

                        ],
                        "threehours": [

                            { time: "09:00-12:00", stats: 22, trend: "down" },
                            { time: "12:00-15:00", stats: 24, trend: "" },
                            { time: "15:00-18:00", stats: 26, trend: "" },
                            { time: "18:00-21:00", stats: 56, trend: "up" }


                        ]
                    }

                },
                "cardTargetStatListTicketAverage": {
                    "data": {
                        "onehour": [

                            { time: "09:00-10:00", stats: 66.86, trend: "" },
                            { time: "10:00-11:00", stats: 81.32, trend: "up" },
                            { time: "11:00-12:00", stats: 56.86, trend: "" },
                            { time: "12:00-13:00", stats: 62.94, trend: "" },
                            { time: "13:00-14:00", stats: 53.45, trend: "" },
                            { time: "14:00-15:00", stats: 56.14, trend: "" },
                            { time: "15:00-16:00", stats: 63.53, trend: "" },
                            { time: "16:00-17:00", stats: 81.20, trend: "" },
                            { time: "17:00-18:00", stats: 48.24, trend: "down" },
                            { time: "18:00-19:00", stats: 55.05, trend: "" },
                            { time: "19:00-20:00", stats: 75.61, trend: "" },
                            { time: "20:00-21:00", stats: 64.76, trend: "" },
                            { time: "21:00-22:00", stats: 0.00, trend: "" }

                        ],
                        "threehours": [

                            { time: "09:00-12:00", stats: 64.89, trend: "" },
                            { time: "12:00-15:00", stats: 58.36, trend: "down" },
                            { time: "15:00-18:00", stats: 59.47, trend: "" },
                            { time: "18:00-21:00", stats: 66.27, trend: "up" }
                        ],
                    }
                }
            }
        },
        "attendance": {
            "summaryCardAttendance": {
                "dailypresence": "758",
                "realtimepresence": "5"
            },
            "barChartCard": {
                "data": {
                    "onehour": [

                        { time: '09:00', sales: 15 },
                        { time: '10:00', sales: 27 },
                        { time: '11:00', sales: 69 },
                        { time: '12:00', sales: 125 },
                        { time: '13:00', sales: 73 },
                        { time: '14:00', sales: 41 },
                        { time: '15:00', sales: 36 },
                        { time: '16:00', sales: 54 },
                        { time: '17:00', sales: 83 },
                        { time: '18:00', sales: 123 },
                        { time: '19:00', sales: 75 },
                        { time: '20:00', sales: 37 },
                        { time: '21:00', sales: 0 }


                    ],
                    "threehours": [

                        { time: '09:00', sales: 111 },
                        { time: '12:00', sales: 239 },
                        { time: '15:00', sales: 173 },
                        { time: '18:00', sales: 235 },
                        { time: '21:00', sales: 0 }

                    ]
                }
            },
            "lineChartCard": {
                "data": [

                    { time: '09:00', sales: 0 },
                    { time: '12:00', sales: 111 },
                    { time: '15:00', sales: 350 },
                    { time: '18:00', sales: 523 },
                    { time: '21:00', sales: 758 }
                ]
            },
            "tabTargetAattendance": {
                "cardTargetDailyAttendance": {
                    "state": "progress", //success startNew progress
                    "nametarget": "Aumenta presenze",
                    "min": 0,
                    "max": 1000,
                    "value": 758
                },
                "cardTargetStatListDailyAttendance": {
                    "data": {
                        "onehour": [

                            { time: "09:00-10:00", stats: 15, trend: "down" },
                            { time: "10:00-11:00", stats: 27, trend: "" },
                            { time: "11:00-12:00", stats: 69, trend: "" },
                            { time: "12:00-13:00", stats: 125, trend: "" },
                            { time: "13:00-14:00", stats: 73, trend: "" },
                            { time: "14:00-15:00", stats: 41, trend: "" },
                            { time: "15:00-16:00", stats: 36, trend: "" },
                            { time: "16:00-17:00", stats: 54, trend: "" },
                            { time: "17:00-18:00", stats: 83, trend: "" },
                            { time: "18:00-19:00", stats: 123, trend: "up" },
                            { time: "19:00-20:00", stats: 75, trend: "" },
                            { time: "20:00-21:00", stats: 37, trend: "" }

                        ],
                        "threehours": [

                            { time: "09:00-12:00", stats: 111, trend: "down" },
                            { time: "12:00-15:00", stats: 239, trend: "up" },
                            { time: "15:00-18:00", stats: 173, trend: "" },
                            { time: "18:00-21:00", stats: 235, trend: "" }

                        ],
                    }
                }

            }

        },
        "ecommerce": {
            "summaryCardEcommerce": {
                "revenue": 7123.10,
                "transaction": 289,
                "transactionaverage": 24.65
            },
            "lineChartCard": {
                "data": [

                    { time: '09:00', sales: 0.00 },
                    { time: '12:00', sales: 1096.10 },
                    { time: '15:00', sales: 2462.94 },
                    { time: '18:00', sales: 4259.42 },
                    { time: '21:00', sales: 7123.10 }
                ]
            },
            "barChartCard": {
                "data": {
                    "onehour": [

                        { time: '09:00', sales: 68.52 },
                        { time: '10:00', sales: 258.24 },
                        { time: '11:00', sales: 769.34 },
                        { time: '12:00', sales: 258.57 },
                        { time: '13:00', sales: 587.02 },
                        { time: '14:00', sales: 521.25 },
                        { time: '15:00', sales: 365.25 },
                        { time: '16:00', sales: 405.98 },
                        { time: '17:00', sales: 1025.25 },
                        { time: '18:00', sales: 825.54 },
                        { time: '19:00', sales: 1689.36 },
                        { time: '20:00', sales: 348.78 },
                        { time: '21:00', sales: 348.78 }


                    ],
                    "threehours": [

                        { time: '09:00', sales: 518.87 },
                        { time: '12:00', sales: 1096.10 },
                        { time: '15:00', sales: 1366.84 },
                        { time: '18:00', sales: 1796.48 },
                        { time: '21:00', sales: 2863.68 }

                    ]
                }
            },
            "tabTargetEcommerce": {

                "cardTargetDetails": {
                    "reveneu": 7123.10,
                    "transaction": 289,
                    "transactionaverage": 24.65,
                    "shoppingcart": 469,
                    "session": 7035,
                    "user": 2345
                },
                "cardTargetReveneu": {
                    "state": "progress", //success startNew progress
                    "nametarget": "Aumenta fatturato",
                    "min": 0,
                    "max": 10000,
                    "value": 7123.10
                },
                "cardTargetTransaction": {
                    "state": "success", //success startNew progress
                    "nametarget": "Aumenta transazioni",
                    "min": 0,
                    "max": 250,
                    "value": 289
                },
                "cardTargetTransactionAverage": {
                    "state": "startNew", //success startNew progress
                    "nametarget": "Aumenta transazione media",
                    "min": 0,
                    "max": 100,
                    "value": 40
                },
                "cardTargetShoppingCart": {
                    "state": "progress", //success startNew progress
                    "nametarget": "Aumenta carrelli",
                    "min": 0,
                    "max": 1000,
                    "value": 469
                },
                "cardTargetSession": {
                    "state": "success", //success startNew progress
                    "nametarget": "Aumenta sessioni",
                    "min": 0,
                    "max": 5000,
                    "value": 7035
                },
                "cardTargetUser": {
                    "state": "success", //success startNew progress
                    "nametarget": "Aumenta visitatori unici",
                    "min": 0,
                    "max": 2000,
                    "value": 2345
                },
                "cardTargetStatListReveneu": {
                    "data": {
                        "onehour": [

                            { time: "09:00-10:00", stats: 68.52, trend: "down" },
                            { time: "10:00-11:00", stats: 258.24, trend: "" },
                            { time: "11:00-12:00", stats: 769.34, trend: "" },
                            { time: "12:00-13:00", stats: 258.57, trend: "" },
                            { time: "13:00-14:00", stats: 587.02, trend: "" },
                            { time: "14:00-15:00", stats: 521.25, trend: "" },
                            { time: "15:00-16:00", stats: 365.25, trend: "" },
                            { time: "16:00-17:00", stats: 405.98, trend: "" },
                            { time: "17:00-18:00", stats: 1025.25, trend: "" },
                            { time: "18:00-19:00", stats: 825.54, trend: "" },
                            { time: "19:00-20:00", stats: 1689.36, trend: "up" },
                            { time: "20:00-21:00", stats: 348.78, trend: "" },
                            { time: "21:00-22:00", stats: 489.55, trend: "" }

                        ],
                        "threehours": [

                            { time: "09:00-12:00", stats: 1096.10, trend: "down" },
                            { time: "12:00-15:00", stats: 1366.84, trend: "" },
                            { time: "15:00-18:00", stats: 1796.48, trend: "" },
                            { time: "18:00-21:00", stats: 2863.68, trend: "up" }


                        ]
                    }
                },
                "cardTargetStatListTransaction": {
                    "data": {
                        "onehour": [

                            { time: "09:00-10:00", stats: 1, trend: "down" },
                            { time: "10:00-11:00", stats: 5, trend: "" },
                            { time: "11:00-12:00", stats: 12, trend: "" },
                            { time: "12:00-13:00", stats: 16, trend: "" },
                            { time: "13:00-14:00", stats: 21, trend: "" },
                            { time: "14:00-15:00", stats: 16, trend: "" },
                            { time: "15:00-16:00", stats: 15, trend: "" },
                            { time: "16:00-17:00", stats: 7, trend: "" },
                            { time: "17:00-18:00", stats: 40, trend: "" },
                            { time: "18:00-19:00", stats: 32, trend: "" },
                            { time: "19:00-20:00", stats: 98, trend: "up" },
                            { time: "20:00-21:00", stats: 26, trend: "" }


                        ],
                        "threehours": [

                            { time: "09:00-12:00", stats: 18, trend: "down" },
                            { time: "12:00-15:00", stats: 53, trend: "" },
                            { time: "15:00-18:00", stats: 62, trend: "" },
                            { time: "18:00-21:00", stats: 156, trend: "up" }

                        ],
                    }
                },
                "cardTargetStatListTransactionAverage": {
                    "data": {
                        "onehour": [

                            { time: "09:00-10:00", stats: 68.52, trend: "up" },
                            { time: "10:00-11:00", stats: 51.65, trend: "" },
                            { time: "11:00-12:00", stats: 64.11, trend: "" },
                            { time: "12:00-13:00", stats: 16.16, trend: "down" },
                            { time: "13:00-14:00", stats: 27.95, trend: "" },
                            { time: "14:00-15:00", stats: 32.58, trend: "" },
                            { time: "15:00-16:00", stats: 24.35, trend: "" },
                            { time: "16:00-17:00", stats: 58.00, trend: "" },
                            { time: "17:00-18:00", stats: 25.63, trend: "" },
                            { time: "18:00-19:00", stats: 25.80, trend: "" },
                            { time: "19:00-20:00", stats: 17.24, trend: "" },
                            { time: "20:00-21:00", stats: 13.41, trend: "" }

                        ],
                        "threehours": [

                            { time: "09:00-12:00", stats: 60.89, trend: "up" },
                            { time: "12:00-15:00", stats: 25.79, trend: "" },
                            { time: "15:00-18:00", stats: 28.98, trend: "" },
                            { time: "18:00-21:00", stats: 18.36, trend: "down" }

                        ],
                    }
                },
                "cardTargetStatListShoppingCart": {
                    "data": {
                        "onehour": [

                            { time: "09:00-10:00", stats: 16, trend: "down" },
                            { time: "10:00-11:00", stats: 20, trend: "" },
                            { time: "11:00-12:00", stats: 27, trend: "" },
                            { time: "12:00-13:00", stats: 31, trend: "" },
                            { time: "13:00-14:00", stats: 36, trend: "" },
                            { time: "14:00-15:00", stats: 31, trend: "" },
                            { time: "15:00-16:00", stats: 30, trend: "" },
                            { time: "16:00-17:00", stats: 22, trend: "" },
                            { time: "17:00-18:00", stats: 55, trend: "" },
                            { time: "18:00-19:00", stats: 47, trend: "" },
                            { time: "19:00-20:00", stats: 113, trend: "up" },
                            { time: "20:00-21:00", stats: 41, trend: "" },
                            { time: "21:00-22:00", stats: 59, trend: "" }

                        ],
                        "threehours": [

                            { time: "09:00-12:00", stats: 63, trend: "down" },
                            { time: "12:00-15:00", stats: 98, trend: "" },
                            { time: "15:00-18:00", stats: 107, trend: "" },
                            { time: "18:00-21:00", stats: 201, trend: "up" }

                        ],
                    }
                },
                "cardTargetStatListSession": {
                    "data": {
                        "onehour": [

                            { time: "09:00-10:00", stats: 240, trend: "down" },
                            { time: "10:00-11:00", stats: 300, trend: "" },
                            { time: "11:00-12:00", stats: 405, trend: "" },
                            { time: "12:00-13:00", stats: 465, trend: "" },
                            { time: "13:00-14:00", stats: 540, trend: "" },
                            { time: "14:00-15:00", stats: 465, trend: "" },
                            { time: "15:00-16:00", stats: 450, trend: "" },
                            { time: "16:00-17:00", stats: 330, trend: "" },
                            { time: "17:00-18:00", stats: 825, trend: "" },
                            { time: "18:00-19:00", stats: 705, trend: "" },
                            { time: "19:00-20:00", stats: 1695, trend: "up" },
                            { time: "20:00-21:00", stats: 615, trend: "" }


                        ],
                        "threehours": [

                            { time: "09:00-12:00", stats: 945, trend: "down" },
                            { time: "12:00-15:00", stats: 1470, trend: "" },
                            { time: "15:00-18:00", stats: 1605, trend: "" },
                            { time: "18:00-21:00", stats: 3015, trend: "up" }
                        ],
                    }
                },
                "cardTargetStatListUser": {
                    "data": {
                        "onehour": [

                            { time: "09:00-10:00", stats: 80, trend: "down" },
                            { time: "10:00-11:00", stats: 100, trend: "" },
                            { time: "11:00-12:00", stats: 135, trend: "" },
                            { time: "12:00-13:00", stats: 155, trend: "" },
                            { time: "13:00-14:00", stats: 180, trend: "" },
                            { time: "14:00-15:00", stats: 155, trend: "" },
                            { time: "15:00-16:00", stats: 150, trend: "" },
                            { time: "16:00-17:00", stats: 110, trend: "" },
                            { time: "17:00-18:00", stats: 275, trend: "" },
                            { time: "18:00-19:00", stats: 235, trend: "" },
                            { time: "19:00-20:00", stats: 565, trend: "" },
                            { time: "20:00-21:00", stats: 205, trend: "" },
                            { time: "21:00-22:00", stats: 295, trend: "up" }

                        ],
                        "threehours": [

                            { time: "09:00-12:00", stats: 315, trend: "down" },
                            { time: "12:00-15:00", stats: 490, trend: "" },
                            { time: "15:00-18:00", stats: 535, trend: "" },
                            { time: "18:00-21:00", stats: 1005, trend: "up" },

                        ],
                    }
                }


            }
        }

    },

    "week": {
        "tip": {
            "title": "titleweekly",
            "description": "descriptionweekly"
        },
        "tabButton": {
            "store": 89782.07,
            "presence": 6521,
            "online": 91027.82,
        },
        "overview": {
            "totalTurnoverCard": {
                "total": 180809.89,
                "store": 89782.07,
                "online": 91027.82,
                "pieChart": [
                    { x: 'store', y: 49.66 },
                    { x: 'online', y: 50.34 }
                ]
            },
            "totalTransactionCard": {
                "total": 3017,
                "store": 1251,
                "online": 1766,
                "pieChart": [
                    { x: 'store', y: 41.47 },
                    { x: 'online', y: 58.93 }
                ],
                "ticket": 59.93
            },
            "clientCard": {
                "storeClient": 13.77,
                "ticket": 1251,
                "presence": 6521,
                "percentageStore": 19.18,
                "onlineClient": 0.98,
                "transaction": 1766,
                "unicClient": 93270,
                "percentageOnline": 1.89
            },
            "trafficComparision": {
                "data": [
                    { xName: "Lun", x: "Lun", y2: 758, y: 2345 },
                    { xName: "Mar", x: "Mar", y2: 1284, y: 15240 },
                    { xName: "Mer", x: "Mer", y2: 1578, y: 27120 },
                    { xName: "Gio", x: "Gio", y2: 2156, y: 42045 },
                    { xName: "Ven", x: "Ven", y2: 3131, y: 57820 },
                    { xName: "Sab", x: "Sab", y2: 4165, y: 74645 },
                    { xName: "Dom", x: "Dom", y2: 6521, y: 93270 }
                ]

            },
            "targetCard": {
                "presenceTarget": "Aumenta presenze",
                "valuePresence": 6521,
                "maxPresence": 7000,
                "onlineTarget": "Aumenta fatturato",
                "valueOnline": 91027.82,
                "maxOnline": 100000.00
            }
        },
        "store": {
            "summaryCardStore": {
                "reveneu": 89782.07,
                "transaction": 1251,
                "transactionaverage": 71.77,
            },

            "lineChartCard": {
                "data": [
                    { time: 'Lun ', sales: 8085.35 },
                    { time: 'Mar ', sales: 16037.96 },
                    { time: 'Mer ', sales: 25114.43 },
                    { time: 'Gio ', sales: 35944.54 },
                    { time: 'Ven ', sales: 48368.52 },
                    { time: 'Sab ', sales: 68212.82 },
                    { time: 'Dom ', sales: 89782.07 },

                ]
            },
            "barChartCard": {
                "data": {
                    "day": [
                        { time: 'Lun ', sales: 8085.35 },
                        { time: 'Mar ', sales: 7952.61 },
                        { time: 'Mer ', sales: 9076.47 },
                        { time: 'Gio ', sales: 10830.11 },
                        { time: 'Ven ', sales: 12423.98 },
                        { time: 'Sab ', sales: 19844.30 },
                        { time: 'Dom ', sales: 21569.25 }

                    ],
                }
            },

            "tabTargetStore": {
                "cardTargetRevenueStore": {
                    "state": "progress", //success startNew progress
                    "nametarget": "Aumenta fatturato",
                    "min": 0,
                    "max": 90000.00,
                    "value": 89782.07
                },
                "cardTargetTicketStore": {
                    "state": "success", //success startNew progress
                    "nametarget": "Aumenta scontrini",
                    "min": 0,
                    "max": 1000,
                    "value": 1251
                },
                "cardTargetTicketAverageStore": {
                    "state": "startNew", //success startNew progress
                    "nametarget": "Aumenta scontrino medio",
                    "min": 0,
                    "max": 50,
                    "value": 50
                },
                "cardTargetStatListReveneu": {
                    
                    "data": {
                        "day": [
                            { time: 'Lun ', stats: 8085.35, trend: "" },
                            { time: 'Mar ', stats: 7952.61, trend: "down" },
                            { time: 'Mer ', stats: 9076.47, trend: "" },
                            { time: 'Gio ', stats: 10830.11, trend: "" },
                            { time: 'Ven ', stats: 12423.98, trend: "" },
                            { time: 'Sab ', stats: 19844.30, trend: "" },
                            { time: 'Dom ', stats: 21569.25, trend: "up" }

                        ]
                    }
                },
                "cardTargetStatListTicket": {
                    "data": {
                        "day": [
                            { time: 'Lun ', stats: "128", trend: "" },
                            { time: 'Mar ', stats: "97", trend: "" },
                            { time: 'Mer ', stats: "56", trend: "down" },
                            { time: 'Gio ', stats: "178", trend: "" },
                            { time: 'Ven ', stats: "212", trend: "" },
                            { time: 'Sab ', stats: "254", trend: "" },
                            { time: 'Dom ', stats: "326", trend: "up" }
                        ]
                    }

                },
                "cardTargetStatListTicketAverage": {
                    "data": {
                        "day": [
                            { time: 'Lun ', stats: 63.17, trend: "" },
                            { time: 'Mar ', stats: 81.99, trend: "" },
                            { time: 'Mer ', stats: 162.08, trend: "up" },
                            { time: 'Gio ', stats: 60.84, trend: "" },
                            { time: 'Ven ', stats: 58.60, trend: "down" },
                            { time: 'Sab ', stats: 78.13, trend: "" },
                            { time: 'Dom ', stats: 66.16, trend: "" }
                        ]
                    }
                }
            }
        },
        "attendance": {
            "summaryCardAttendance": {
                "dailypresence": 6521,
                "realtimepresence": 5
            },
            "barChartCard": {
                "data": {
                    "day": [
                        { time: 'Lun ', sales: 758 },
                        { time: 'Mar ', sales: 526 },
                        { time: 'Mer ', sales: 294 },
                        { time: 'Gio ', sales: 587 },
                        { time: 'Ven ', sales: 975 },
                        { time: 'Sab ', sales: 1034 },
                        { time: 'Dom ', sales: 2356 }
                    ]
                }
            },
            "lineChartCard": {
                "data": [
                    { time: 'Lun ', sales: 758 },
                    { time: 'Mar ', sales: 1286 },
                    { time: 'Mer ', sales: 1578 },
                    { time: 'Gio ', sales: 2156 },
                    { time: 'Ven ', sales: 3131 },
                    { time: 'Sab ', sales: 4165 },
                    { time: 'Dom ', sales: 6521 }
                ]
            },
            "tabTargetAattendance": {
                "cardTargetDailyAttendance": {
                    "state": "success", //success startNew progress
                    "nametarget": "Aumenta presenze",
                    "min": 0,
                    "max": 5000,
                    "value": 6521
                },
                "cardTargetStatListDailyAttendance": {
                    "data": {
                        "day": [
                            { time: 'Lun ', stats: 758, trend: "" },
                            { time: 'Mar ', stats: 526, trend: "" },
                            { time: 'Mer ', stats: 294, trend: "down" },
                            { time: 'Gio ', stats: 587, trend: "" },
                            { time: 'Ven ', stats: 975, trend: "" },
                            { time: 'Sab ', stats: 1034, trend: "" },
                            { time: 'Dom ', stats: 2356, trend: "up" }

                        ]
                    }
                }

            }

        },
        "ecommerce": {
            "summaryCardEcommerce": {
                "revenue": 91087.82,
                "transaction": 1766,
                "transactionaverage": 51.54
            },
            "lineChartCard": {
                "data": [
                    { time: 'Lun ', sales: 7123.10 },
                    { time: 'Mar ', sales: 16329.71 },
                    { time: 'Mer ', sales: 26660.18 },
                    { time: 'Gio ', sales: 38744.29 },
                    { time: 'Ven ', sales: 52422.27 },
                    { time: 'Sab ', sales: 71012.57 },
                    { time: 'Dom ', sales: 91087.82 }
                ]
            },
            "barChartCard": {
                "data": {
                    "day": [
                        { time: 'Lun ', sales: 7123.10 },
                        { time: 'Mar ', sales: 9206.61 },
                        { time: 'Mer ', sales: 10330.47 },
                        { time: 'Gio ', sales: 12084.11 },
                        { time: 'Ven ', sales: 13677.98 },
                        { time: 'Sab ', sales: 18590.30 },
                        { time: 'Dom ', sales: 20015.25 }

                    ]
                }
            },
            "tabTargetEcommerce": {

                "cardTargetDetails": {
                    "reveneu": 91087.82,
                    "transaction": 1766,
                    "transactionaverage": 51.54,
                    "shoppingcart": 4106,
                    "session": 59772,
                    "user": "93270"
                },
                "cardTargetReveneu": {
                    "state": "success", //success startNew progress
                    "nametarget": "Aumenta fatturato",
                    "min": 0,
                    "max": 90000,
                    "value": 91087.82
                },
                "cardTargetTransaction": {
                    "state": "progress", //success startNew progress
                    "nametarget": "Aumenta transazioni",
                    "min": 0,
                    "max": 2000,
                    "value": 1766
                },
                "cardTargetTransactionAverage": {
                    "state": "startNew", //success startNew progress
                    "nametarget": "Aumenta transazione media",
                    "min": 0,
                    "max": 100,
                    "value": 40
                },
                "cardTargetShoppingCart": {
                    "state": "progress", //success startNew progress
                    "nametarget": "Aumenta carrelli",
                    "min": 0,
                    "max": 5000,
                    "value": 4106
                },
                "cardTargetSession": {
                    "state": "success", //success startNew progress
                    "nametarget": "Aumenta sessioni",
                    "min": 0,
                    "max": 5500,
                    "value": 59772
                },
                "cardTargetUser": {
                    "state": "startNew", //success startNew progress
                    "nametarget": "Aumenta visitatori unici",
                    "min": 0,
                    "max": 15000,
                    "value": 18867
                },
                "cardTargetStatListReveneu": {
                    "data": {
                        "day": [
                            { time: 'Lun ', stats: 7123.10, trend: "down" },
                            { time: 'Mar ', stats: 9206.61, trend: "" },
                            { time: 'Mer ', stats: 10330.47, trend: "" },
                            { time: 'Gio ', stats: 12084.11, trend: "" },
                            { time: 'Ven ', stats: 13677.98, trend: "" },
                            { time: 'Sab ', stats: 18590.30, trend: "" },
                            { time: 'Dom ', stats: 20015.25, trend: "up" }
                        ]
                    }
                },
                "cardTargetStatListTransaction": {
                    "data": {
                        "day": [
                            { time: 'Lun ', stats: 289, trend: "" },
                            { time: 'Mar ', stats: 156, trend: "" },
                            { time: 'Mer ', stats: 115, trend: "down" },
                            { time: 'Gio ', stats: 237, trend: "" },
                            { time: 'Ven ', stats: 271, trend: "" },
                            { time: 'Sab ', stats: 313, trend: "" },
                            { time: 'Dom ', stats: 385, trend: "up" }
                        ]
                    }
                },
                "cardTargetStatListTransactionAverage": {
                    "data": {
                        "day": [
                            { time: 'Lun ', stats: 24.65, trend: "down" },
                            { time: 'Mar ', stats: 59.02, trend: "" },
                            { time: 'Mer ', stats: 89.83, trend: "upn" },
                            { time: 'Gio ', stats: 50.99, trend: "" },
                            { time: 'Ven ', stats: 50.47, trend: "" },
                            { time: 'Sab ', stats: 59.39, trend: "" },
                            { time: 'Dom ', stats: 51.99, trend: "" }
                        ]
                    }
                },
                "cardTargetStatListShoppingCart": {
                    "data": {
                        "day": [
                            { time: 'Lun ', stats: 469, trend: "down" },
                            { time: 'Mar ', stats: 516, trend: "" },
                            { time: 'Mer ', stats: 475, trend: "" },
                            { time: 'Gio ', stats: 597, trend: "" },
                            { time: 'Ven ', stats: 631, trend: "" },
                            { time: 'Sab ', stats: 673, trend: "" },
                            { time: 'Dom ', stats: 745, trend: "up" }
                        ]
                    }
                },
                "cardTargetStatListSession": {
                    "data": {
                        "day": [
                            { time: 'Lun ', stats: 7035, trend: "" },
                            { time: 'Mar ', stats: 7482, trend: "" },
                            { time: 'Mer ', stats: 6888, trend: "down" },
                            { time: 'Gio ', stats: 8657, trend: "" },
                            { time: 'Ven ', stats: 9150, trend: "" },
                            { time: 'Sab ', stats: 9759, trend: "" },
                            { time: 'Dom ', stats: 10803, trend: "up" }
                        ]
                    }
                },
                "cardTargetStatListUser": {
                    "data": {
                        "day": [
                            { time: 'Lun ', stats: 2345, trend: "down" },
                            { time: 'Mar ', stats: 12900, trend: "" },
                            { time: 'Mer ', stats: 11875, trend: "" },
                            { time: 'Gio ', stats: 14925, trend: "" },
                            { time: 'Ven ', stats: 15775, trend: "" },
                            { time: 'Sab ', stats: 16825, trend: "" },
                            { time: 'Dom ', stats: 18625, trend: "up" }
                        ]
                    }
                }


            }
        }
    },
    "month": {
        "tip": {
            "title": "titlemonthly",
            "description": "descriptionmonthly"
        },
        "tabButton": {
            "store": 500811.07,
            "presence": 48970,
            "online": 281741.02,
        },
        "overview": {
            "totalTurnoverCard": {
                "total": 782552.09,
                "store": 500811.07,
                "online": 281741.02,
                "pieChart": [
                    { x: 'store', y: 64.00 },
                    { x: 'online', y: 36.00 }
                ]
            },
            "totalTransactionCard": {
                "total": 11001,
                "store": 6918,
                "online": 4083,
                "pieChart": [
                    { x: 'store', y: 62.89 },
                    { x: 'online', y: 37.11 }
                ],
                "ticket": 71.13
            },
            "clientCard": {
                "storeClient": 10.23,
                "ticket": 6918,
                "presence": 48970,
                "percentageStore": 14.13,
                "onlineClient": 0.70,
                "transaction": 4083,
                "unicClient": 403195,
                "percentageOnline": 1.01
            },
            "trafficComparision": {
                "data": [
                    { xName: "Set 1", x: " Set 1", y2: 6521, y: 93270 },
                    { xName: "Set 2", x: " Set 2", y2: 16396, y: 170720 },
                    { xName: "Set 3", x: "Set 3", y2: 19965, y: 242820 },
                    { xName: "Set 4", x: "Set 4", y2: 31990, y: 337070 },
                    { xName: "Set 5", x: "Set 5", y2: 48970, y: 403195 }

                ]

            },
            "targetCard": {
                "presenceTarget": "Aumenta presenze",
                "valuePresence": 48970,
                "maxPresence": 50000,
                "onlineTarget": "Aumenta fatturato",
                "valueOnline": 281741.02,
                "maxOnline": 300000.00
            }
        },
        "store": {
            "summaryCardStore": {
                "reveneu": 500811.07,
                "transaction": 6918,
                "transactionaverage": 72.39,
            },

            "lineChartCard": {
                "data": [
                    { time: 'Set 1 ', sales: 89782.07 },
                    { time: 'Set 2 ', sales: 192138.32 },
                    { time: 'Set 3 ', sales: 285716.56 },
                    { time: 'Set 4 ', sales: 385152.80 },
                    { time: 'Set 5 ', sales: 500811.07 }

                ]
            },
            "barChartCard": {
                "data": {
                    "week": [
                        { time: 'Set 1 ', sales: 89782.07 },
                        { time: 'Set 2 ', sales: 102356.25 },
                        { time: 'Set 3 ', sales: 93578.24 },
                        { time: 'Set 4 ', sales: 35944.54 },
                        { time: 'Set 5 ', sales: 48368.52 }

                    ],
                }
            },

            "tabTargetStore": {
                "cardTargetRevenueStore": {
                    "state": "progress", // progress
                    "nametarget": "Aumenta fatturato",
                    "min": 0,
                    "max": 501800,
                    "value": 500811.07
                },
                "cardTargetTicketStore": {
                    "state": "success", //success startNew progress
                    "nametarget": "Aumenta scontrini",
                    "min": 0,
                    "max": 6000,
                    "value": 6918
                },
                "cardTargetTicketAverageStore": {
                    "state": "startNew", //success startNew progress
                    "nametarget": "Aumenta scontrino medio",
                    "min": 0,
                    "max": 50,
                    "value": 50
                },
                "cardTargetStatListReveneu": {
                    "data": {
                        "week": [
                            { time: 'Set 1 ', stats: 89782.07, trend: "down" },
                            { time: 'Set 2 ', stats: 102356.25, trend: "" },
                            { time: 'Set 3 ', stats: 93578.24, trend: "" },
                            { time: 'Set 4 ', stats: 99436.24, trend: "" },
                            { time: 'Set 5 ', stats: 115658.27, trend: "up" }

                        ]
                    }
                },
                "cardTargetStatListTicket": {
                    "data": {
                        "week": [
                            { time: 'Set 1 ', stats: 1251, trend: "" },
                            { time: 'Set 2 ', stats: 1758, trend: "up" },
                            { time: 'Set 3 ', stats: 1598, trend: "" },
                            { time: 'Set 4 ', stats: 986, trend: "down" },
                            { time: 'Set 5 ', stats: 1325, trend: "" }
                        ]
                    }

                },
                "cardTargetStatListTicketAverage": {
                    "data": {
                        "week": [
                            { time: 'Set 1 ', stats: 71.77, trend: "" },
                            { time: 'Set 2 ', stats: 58.22, trend: "down" },
                            { time: 'Set 3 ', stats: 58.56, trend: "" },
                            { time: 'Set 4 ', stats: 100.85, trend: "up" },
                            { time: 'Set 5 ', stats: 87.29, trend: "" }
                        ]
                    }
                }
            }
        },
        "attendance": {
            "summaryCardAttendance": {
                "dailypresence": 48970,
                "realtimepresence": 5
            },
            "barChartCard": {
                "data": {
                    "week": [
                        { time: 'Set 1 ', sales: 6521 },
                        { time: 'Set 2 ', sales: 9875 },
                        { time: 'Set 3 ', sales: 3569 },
                        { time: 'Set 4 ', sales: 12025 },
                        { time: 'Set 5 ', sales: 16980 }
                    ]
                }
            },
            "lineChartCard": {
                "data": [
                    { time: 'Set 1 ', sales: 6521 },
                    { time: 'Set 2 ', sales: 16396 },
                    { time: 'Set 3 ', sales: 19965 },
                    { time: 'Set 4 ', sales: 31990 },
                    { time: 'Set 5 ', sales: 48970 }
                ]
            },
            "tabTargetAattendance": {
                "cardTargetDailyAttendance": {
                    "state": "progress", //progress
                    "nametarget": "Aumenta presenze",
                    "min": 0,
                    "max": 50000,
                    "value": 48970
                },
                "cardTargetStatListDailyAttendance": {
                    "data": {
                        "week": [
                            { time: 'Set 1 ', stats: 6521, trend: "" },
                            { time: 'Set 2 ', stats: 9875, trend: "" },
                            { time: 'Set 3 ', stats: 3569, trend: "down" },
                            { time: 'Set 4 ', stats: 12025, trend: "" },
                            { time: 'Set 5 ', stats: 16980, trend: "up" }

                        ]
                    }
                }

            }

        },
        "ecommerce": {
            "summaryCardEcommerce": {
                "revenue": 281741.02,
                "transaction": 4083,
                "transactionaverage": 69.00
            },
            "lineChartCard": {
                "data": [
                    { time: 'Set 1 ', sales: 91027.82 },
                    { time: 'Set 2 ', sales: 141397.39 },
                    { time: 'Set 3 ', sales: 167186.63 },
                    { time: 'Set 4 ', sales: 272975.75 },
                    { time: 'Set 5 ', sales: 281741.02 }
                ]
            },
            "barChartCard": {
                "data": {
                    "week": [
                        { time: 'Set 1 ', sales: 91027.82 },
                        { time: 'Set 2 ', sales: 50369.57 },
                        { time: 'Set 3 ', sales: 25789.24 },
                        { time: 'Set 4 ', sales: 105789.12 },
                        { time: 'Set 5 ', sales: 8765.27 }

                    ]
                }
            },
            "tabTargetEcommerce": {

                "cardTargetDetails": {
                    "reveneu": 281741.02,
                    "transaction": 4083,
                    "transactionaverage": 69.00,
                    "shoppingcart": 16503,
                    "session": 205436,
                    "user": 403195
                },
                "cardTargetReveneu": {
                    "state": "progress", // progress
                    "nametarget": "Aumenta fatturato",
                    "min": 0,
                    "max": 300000.00,
                    "value": 281741.02
                },
                "cardTargetTransaction": {
                    "state": "success",
                    "nametarget": "Aumenta transazioni",
                    "min": 0,
                    "max": 4000,
                    "value": 4083
                },
                "cardTargetTransactionAverage": {
                    "state": "startNew",
                    "nametarget": "Aumenta transazione media",
                    "min": 0,
                    "max": 100,
                    "value": 40
                },
                "cardTargetShoppingCart": {
                    "state": "progress", //progress
                    "nametarget": "Aumenta carrelli",
                    "min": 0,
                    "max": 3000,
                    "value": 16503
                },
                "cardTargetSession": {
                    "state": "startNew", //success startNew progress
                    "nametarget": "Aumenta sessioni",
                    "min": 0,
                    "max": 6000,
                    "value": 63837
                },
                "cardTargetUser": {
                    "state": "success", //success startNew progress
                    "nametarget": "Aumenta visitatori unici",
                    "min": 0,
                    "max": 35000,
                    "value": 403195
                },
                "cardTargetStatListReveneu": {
                    "data": {
                        "week": [
                            { time: 'Set 1 ', stats: 91027.82, trend: "" },
                            { time: 'Set 2 ', stats: 50369.57, trend: "" },
                            { time: 'Set 3 ', stats: 25789.24, trend: "" },
                            { time: 'Set 4 ', stats: 105789.12, trend: "up" },
                            { time: 'Set 5 ', stats: 8765.27, trend: "down" }
                        ]
                    }
                },
                "cardTargetStatListTransaction": {
                    "data": {
                        "week": [
                            { time: 'Set 1 ', stats: 1766, trend: "up" },
                            { time: 'Set 2 ', stats: 578, trend: "" },
                            { time: 'Set 3 ', stats: 364, trend: "" },
                            { time: 'Set 4 ', stats: 1250, trend: "" },
                            { time: 'Set 5 ', stats: 125, trend: "down" }
                        ]
                    }
                },
                "cardTargetStatListTransactionAverage": {
                    "data": {
                        "week": [
                            { time: 'Set 1 ', stats: 51.54, trend: "down" },
                            { time: 'Set 2 ', stats: 87.14, trend: "up" },
                            { time: 'Set 3 ', stats: 70.85, trend: "" },
                            { time: 'Set 4 ', stats: 84.63, trend: "" },
                            { time: 'Set 5 ', stats: 70.12, trend: "" }
                        ]
                    }
                },
                "cardTargetStatListShoppingCart": {
                    "data": {
                        "week": [
                            { time: 'Set 1 ', stats: 4106, trend: "up" },
                            { time: 'Set 2 ', stats: 3098, trend: "" },
                            { time: 'Set 3 ', stats: 2884, trend: "" },
                            { time: 'Set 4 ', stats: 3770, trend: "" },
                            { time: 'Set 5 ', stats: 2645, trend: "down" }
                        ]
                    }
                },
                "cardTargetStatListSession": {
                    "data": {
                        "week": [
                            { time: 'Set 1 ', stats: 5977, trend: "down" },
                            { time: 'Set 2 ', stats: 36402, trend: "up" },
                            { time: 'Set 3 ', stats: 33887, trend: "" },
                            { time: 'Set 4 ', stats: 44298, trend: "" },
                            { time: 'Set 5 ', stats: 31079, trend: "" }
                        ]
                    }
                },
                "cardTargetStatListUser": {
                    "data": {
                        "week": [
                            { time: 'Set 1 ', stats: 93270, trend: "down" },
                            { time: 'Set 2 ', stats: 77450, trend: "up" },
                            { time: 'Set 3 ', stats: 72100, trend: "" },
                            { time: 'Set 4 ', stats: 94250, trend: "" },
                            { time: 'Set 5 ', stats: 66125, trend: "" }
                        ]
                    }
                }


            }
        }
    }

}

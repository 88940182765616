import React, { useState } from 'react';
import "./cardlasttarget.css"
import IconFlagGrad from "../../../../../img/IconFlagGrad.png"
import IconSensor from "../../../../../img/IconSensor.png"
import IconEcommercePurple from "../../../../../img/IconEcommercePurple.png"
import IconPurpleUser from "../../../../../img/IconPurpleUser.png"
import IconAzureUser from "../../../../../img/IconAzureUser.png"
import { ProgressCircle } from "./commons/ProgressCircle"
import { useTranslation } from 'react-i18next';



export function CardLastTarget({ CardLastTargetData, dateSelected }) {
    const { t, i18n } = useTranslation();

    return (
        <div id="CardLastTarget" className="shadowDrop borderRadius bgWhite">
            <div className="p-2 p-xl-4 row">
                <div className="sectionRevenueForClient  col-md-4 d-flex flex-column align-items-start flex-column justify-content-end">
                    <img className="iconClientGrad mb-2" src={IconFlagGrad} />
                    <h3 className="textBlack bold mb-0 txtsize-32">{t("cardLastTarget.title")}</h3>
                    <p className="textBlack regular mb-0 txtsize-14">{t("cardLastTarget.description")}</p>

                </div>
                <div className="sectionRevenueForClient px-3 col-md-4 d-flex align-items-start flex-column justify-content-start" >
                    <div className="d-flex flex-row my-4 justify-content-center align-items-center" >
                        <img className="iconStoreGreen me-2" src={IconSensor} />
                        <div className="d-flex flex-column" >
                            <h4 className="textBlack bold txtsize-21 mb-0">{t("cardLastTarget.yourattendance")} <span className="textAzure">{t("cardLastTarget.presence")}</span></h4>
                            <p className="textGrey regular txtsize-14 mb-0">{t("cardLastTarget.dailypresence")}</p>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap align-items-center">
                        <div className=" ">
                            <h4 className="textAzure semibold txtsize-14 text-uppercase mb-1 ">{t("cardLastTarget.nametarget")}</h4>
                            <h4 className="targetName textBlack bold txtsize-16 mb-0 me-2 ">{CardLastTargetData.presenceTarget}</h4>
                            <h4 className="textAzure semibold txtsize-14 text-uppercase mb-1 mt-3">{t("cardLastTarget.start")}</h4>
                            <h4 className=" textBlack regular txtsize-16 mb-0 me-2 ">{dateSelected.startDate.toLocaleString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })}</h4>
                            <h4 className="textAzure semibold txtsize-14 text-uppercase mb-1 mt-3">{t("cardLastTarget.end")}</h4>
                            <h4 className=" textBlack regular txtsize-16 mb-0 me-2 ">{dateSelected.endDate.toLocaleString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })} </h4>
                        </div>
                        <div className="align-self-center text-center targetName">
                            <ProgressCircle
                              animation={{
                                enable: false,
                                duration: 1,
                                delay: 0,
                            }}
                                id={"YourPresence"}
                                PrimaryColor={"#8db9ff"}
                                SecondaryColor={"#7789ff"}
                                min={0}
                                max={CardLastTargetData.maxPresence}
                                value={CardLastTargetData.valuePresence} />
                            <p className="textBlack bold txtsize-18 mb-3 text-center ">{CardLastTargetData.valuePresence.toLocaleString()} / <span className=" textAzure regular txtsize-21 ">{CardLastTargetData.maxPresence.toLocaleString()}<img className="img-icon15 m-1 mt-0" src={IconAzureUser} /></span></p>
                        </div>
                    </div>
                </div>


                <div className="sectionRevenueForClient px-3 col-md-4 d-flex align-items-start flex-column justify-content-start" >
                    <div className="d-flex flex-row my-4 justify-content-center align-items-center" >
                        <img className="iconEcommercePurple me-2" src={IconEcommercePurple} />
                        <div className="d-flex flex-column" >
                            <h4 className="textBlack bold txtsize-21 mb-0">{t("cardLastTarget.youronline")} <span className="textPurple">{t("cardLastTarget.online")}</span></h4>
                            <p className="textGrey regular txtsize-14 mb-0">{t("cardLastTarget.reveneu")}</p>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap align-items-center">
                        <div className=" ">
                            <h4 className="textPurple semibold txtsize-14 text-uppercase mb-1 ">{t("cardLastTarget.nametarget")}</h4>
                            <h4 className="targetName textBlack bold txtsize-16 mb-0 me-2 ">{CardLastTargetData.onlineTarget}</h4>
                            <h4 className="textPurple semibold txtsize-14 text-uppercase mb-1 mt-3">{t("cardLastTarget.start")}</h4>
                            <h4 className=" ì textBlack regular txtsize-16 mb-0 me-2 ">{dateSelected.startDate.toLocaleString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })}</h4>
                            <h4 className="textPurple semibold txtsize-14 text-uppercase mb-1 mt-3">{t("cardLastTarget.end")}</h4>
                            <h4 className=" ì textBlack regular txtsize-16 mb-0 me-2 ">{dateSelected.endDate.toLocaleString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })}</h4>
                        </div>
                        <div className="targetName  align-self-center text-center ">
                            <ProgressCircle
                                animation={{
                                    enable: false,
                                    duration: 2000,
                                    delay: 0,
                                }}
                                id={"YourOnline"}
                                PrimaryColor={"#DB91FF"}
                                SecondaryColor={"#B558FD"}
                                min={0}
                                max={CardLastTargetData.maxOnline}
                                value={CardLastTargetData.valueOnline} />
                            <p className="textBlack bold txtsize-18 mb-3 text-center ">{CardLastTargetData.valueOnline.toLocaleString(undefined, {minimumFractionDigits: 2})} / <span className=" textPurple regular txtsize-21 ">{CardLastTargetData.maxOnline.toLocaleString(undefined, {minimumFractionDigits: 2})} € </span></p>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}
import React, { useState } from 'react';

import IconAzureRealTimeUsers from "../../../../../img/IconAzureRealTimeUsers.png"
import IconAzureDailyUsers from "../../../../../img/IconAzureDailyUsers.png"

import { useTranslation } from 'react-i18next';
import "./summarycardattendance.css"


export function SummaryCardAttendance({summaryCardAttendanceData}) {
    const { t, i18n } = useTranslation();

    return (
        <div id="summaryCardAttendance" className="shadowDrop borderRadius bgWhite">
            <div className="p-4  row  justify-content-center ">
                <div className="col-sm-6 justify-content-center align-self-center">
                    <div className="d-flex flex-wrap justify-content-around 
                     align-self-center align-content-center
                     borderRight
                     align-items-center p-1">

                        <div>
                            <h3 className="textAzure txtsize-16 bold mb-0" >{t("SummaryCardAttendance.dailypresence")}</h3>
                            <p className="textBlack txtsize-24 bold mb-0">{summaryCardAttendanceData.dailypresence.toLocaleString()}</p>
                        </div>
                        <img className=" m-2 img-icon60" src={IconAzureDailyUsers} />
                    </div>
                </div>

          
                <div className="col-sm-6 justify-content-around align-self-center">
                <div className="d-flex flex-wrap justify-content-around  align-self-center  align-content-center align-items-center p-1">

                        <div>
                            <h3 className="textAzure txtsize-16 bold mb-0" >{t("SummaryCardAttendance.realtimepresence")}</h3>
                            <p className="textBlack txtsize-24 bold mb-0">{summaryCardAttendanceData.realtimepresence.toLocaleString()}</p>

                        </div>
                        <img className=" m-2 img-icon60" src={IconAzureRealTimeUsers} />
                    </div>

                </div>

            </div>
        </div>
    );
}
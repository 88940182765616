import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import "./login.css"
import { useTranslation } from 'react-i18next';


export function LoginContent() {
    const { t, i18n } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const [enableButton, setEnableButton] = useState(true);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        if (username.length >= 1 && password.length >= 1) {
            setEnableButton(false)
        } else {
            setEnableButton(true)
        }
    }, [username, password])
    const gotoPage = useHistory();
    return (
        <div id="cardTargetStatList" className="borderRadius bgWhite p-4 ">
            <h3 className="textGrad txtsize-24 bold m-auto mb-2 align-content-center" >{t("login.signin")}</h3>
            <div className="d-flex  flex-column flex-wrap align-content-center justify-content-center my-2">
                <div className="my-5">
                    <h4 className="textGrey txtsize-21 bold  mt-4 mb-2">{t("login.welcome")}</h4>
                    <div className="gradient-input my-3 w-auto">
                        <input
                            className="e-input w-100 py-1 px-2"
                            type="text"
                            placeholder={t("login.placeholderName")}
                            onChange={(e) => setUsername(e.target.value)} />
                    </div>
                    <div className="gradient-input inputShowHide w-100 my-3">
                        <input
                            className="e-input w-100 py-1 px-2"
                            type={showPassword ? "text" : "password"}
                            placeholder={t("login.placeholderPassword")}
                            onChange={(e) => setPassword(e.target.value)} />
                        <span
                            className="textLightGreen bgWhite d-flex align-items-center justify-content-center px-1  textNoselectable regular txtsize-13 pointer"
                            style={{minWidth: "65px" }}
                            onClick={() => setShowPassword(!showPassword)} >
                            {showPassword ?  `${t("login.hide")}` : `${t("login.show")}` }
                        </span>
                    </div>
                </div>
                <ButtonComponent cssClass="gradientButton mb-1 mt-5"
                    disabled={enableButton} onClick={() => gotoPage.push("/home")}>{t("login.buttonsignin")}</ButtonComponent>
                <h4 className="textGrey txtsize-16 regular mb-0 mt-5" >{t("login.forgotpassword")} <a className="textPurple txtsize-16 semibold pointer">{t("login.clickhere")}</a></h4>
            </div>
        </div>
    );
}
